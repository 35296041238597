import {Provider} from './provider';
import {DeviceSettings} from './device-settings';
import {GeoData} from './geodata';

export class Device {
  active: boolean;
  online: boolean;
  dyndns_current: string;
  description: string | null;
  id: number | null;
  label: string;
  providers: Provider[];
  settings: DeviceSettings;
  geodata: GeoData;
  setup_completed: string;
  setup_progress: boolean;
  isCopied?: boolean;
  serial_number: string;
  wifi_password: string;
  wifi_ssid: string;

  constructor(data: any) {
    for (const key of data) {
      this[key] = data[key];
    }
  }
}
