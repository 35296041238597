import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceService} from '../../../shared/services/device-service/device.service';

@Component({
  selector: 'app-manually-enter',
  templateUrl: './manually-enter.component.html',
  styleUrls: ['./manually-enter.component.scss']
})
export class ManuallyEnterComponent implements OnInit {

  public barcode: string;

  constructor(
    private router: Router,
    private deviceService: DeviceService
  ) {
  }

  ngOnInit() {
  }

  submitForm(serialNumber) {
    this.deviceService.deviceId = serialNumber;
    this.router.navigateByUrl('/add-device');
  }

}
