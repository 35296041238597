import {environment} from '../../../environments/environment';

export const API = {
  API_URL: environment.apiUrl,
  API_USERS: `${environment.apiUrl}/users`,
  API_ROUTERS: `${environment.apiUrl}/routers`,
  API_ROUTERS_EXISTING: `${environment.apiUrl}/routers/all/check-for-existence`,
  API_PROVIDERS: `${environment.apiUrl}/providers`,
  API_COUNTRIES: `${environment.apiUrl}/countries`,
  API_LOGIN: `${environment.apiUrl}/login`,
  API_LOGOUT: `${environment.apiUrl}/logout`,
  API_REGISTER: `${environment.apiUrl}/signup`,
  API_SOCIAL_LOGIN: `${environment.apiUrl}/sociallogin`,
  API_FORGOT_PASS: `${environment.apiUrl}/password/email`,
  API_RESET_PASS: `${environment.apiUrl}/password/reset`,
  API_PLANS: `${environment.apiUrl}/plans`,
};
