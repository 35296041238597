import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DeviceComponent} from './device.component';
import {ScanComponent} from './scan/scan.component';
import {SharedModule} from '../shared/shared.module';
import {AddServiceComponent} from './add-service/add-service.component';
import {AddDeviceComponent} from './add-device/add-device.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {ManageDeviceComponent} from './manage-device/manage-device.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ManuallyEnterComponent} from './scan/manually-enter/manually-enter.component';
import {ServicesComponent} from './services/services.component';

@NgModule({
  declarations: [
    ManuallyEnterComponent,
    ServicesComponent,
    AddServiceComponent,
    AddDeviceComponent,
    ManageDeviceComponent,
    DeviceComponent,
    ScanComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    ZXingScannerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    CommonModule,
    SharedModule,
  ]
})
export class DeviceModule {
}
