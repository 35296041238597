import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cardNumber'
})
export class CardNumberPipe implements PipeTransform {

  transform(value: any): any {
    value = value.toString();
    return value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
  }

}
