import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {LoginService} from '../login-service/login.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  private httpHeadersStripe = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Authorization: `Bearer ${environment.STRIPE_KEY}`
  });

  private existStatus = new BehaviorSubject('exist');
  private stripeUrl = 'https://api.stripe.com/v1';

  public cardToken: string;
  private cardsS = new Subject();
  public stripeId: number;
  public cardsArray: any;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
  }

  createStripeCostumer(credentials) {
    const url = `${this.stripeUrl}/customers`;
    const data = `email=${credentials.email}`;
    return this.http.post(url, data, {headers: this.httpHeadersStripe}) as Observable<any>;
  }

  createToken(credentials) {
    const url = `${this.stripeUrl}/tokens`;
    const month: string = credentials.date.slice(0, 2);
    const year: string = '20' + credentials.date.slice(-2);

    const cardData = `card[number]=${credentials.cardNumber}` +
      `&card[exp_month]=${month}` +
      `&card[exp_year]=${year}` +
      `&card[cvc]=${credentials.cvv}` +
      `&card[name]=${credentials.name}`;

    return this.http.post(url, cardData, {headers: this.httpHeadersStripe}) as Observable<any>;
  }

  // addStripeCard(credentials) {
  //   const url = `${this.stripeUrl}/customers/${this.stripeId}/sources`;
  //   return this.createToken(credentials.value).pipe(
  //     switchMap(tokenParams => {
  //       if (this.cardsArray.some(card =>
  //         card.fingerprint === tokenParams.card.fingerprint
  //       )) {
  //         return this.existStatus;
  //       } else {
  //         this.cardToken = `card=${tokenParams.id}`;
  //         return this.http.post(url, this.cardToken, {headers: this.httpHeadersStripe}) as Observable<any>;
  //       }
  //     })
  //   );
  // }

  updateCards(cards) {
    this.cardsS.next(cards);
  }

  cardPay(cardId) {
    const url = `${this.stripeUrl}/charges`;

    const data = `amount=697` +
      `&customer=${this.stripeId}` +
      `&currency=usd` +
      `&source=${cardId}` +
      `&statement_descriptor=Some pay`;

    return this.http.post(url, data, {headers: this.httpHeadersStripe}) as Observable<any>;
  }

  // updateCustomerData(email) {
  //   const url = `${this.stripeUrl}/customers/${this.stripeId}`;
  //
  //   const data = `email=${email}`;
  //   return this.http.post(url, data, {headers: this.httpHeadersStripe}) as Observable<any>;
  // }
}
