import { Component, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, flatMap, switchMap, take, takeUntil} from 'rxjs/operators';

import {ModalService} from '../../shared/modal/modal.service';
import {LoginService} from '../../shared/services/login-service/login.service';
import {DeviceService} from '../../shared/services/device-service/device.service';
import {StripeService} from '../../shared/services/stripe-service/stripe.service';
import {RouterHistoryService} from '../../shared/services/router-history/router-history.service';
import {Plan} from '../../shared/models/plan';
import {GooglePay} from '../../shared/services/external-payments/google-pay';
import {ApplePay} from '../../shared/services/external-payments/apple-pay';
import {environment} from '../../../environments/environment';

declare const Stripe: any;

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('container') container: ElementRef;
  @ViewChild('payment') payment: ElementRef;
  public googlePayClient = null;
  public googlePayBaseConfig = null;
  public paymentDataRequest = null;
  public GooglePayButton: any;
  public nextUrl: string;
  public prevUrl: string;
  public subscription$: Subscription;
  public isSuccess: boolean;
  public modalContent = '';
  public url = '';
  private destroy = new Subject();
  public selectedPlan: Plan;
  public isPaymentAgreed: boolean;
  public addCardForm: FormGroup = new FormGroup({
    cardNumber: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    date: new FormControl('', Validators.required),
    cvv: new FormControl('', Validators.required),
  });

  constructor(
    private router: Router,
    private modalService: ModalService,
    private loginService: LoginService,
    private deviceService: DeviceService,
    private stripeService: StripeService,
    private routerHistoryService: RouterHistoryService,
    private googlePay: GooglePay,
    private applePay: ApplePay,
    private renderer: Renderer2
  ) {
    this.googlePayClient = this.googlePay.GooglePayClient;
    this.googlePayBaseConfig = this.googlePay.GooglePayBaseConfig;
    this.paymentDataRequest = this.googlePay.PaymentDataRequest;

    this.googlePayClient.isReadyToPay(this.googlePayBaseConfig)
      .then((response) => {
        if (response.result) {
          this.GooglePayButton = this.googlePayClient.createButton({
            buttonColor: 'black',
            buttonType: 'short',
            onClick: () => this.loadPaymentData()
          });
          this.renderer.appendChild(this.container.nativeElement, this.GooglePayButton);
        }
      });
  }

  ngOnInit() {
    this.prevUrl = this.routerHistoryService.getPreviousUrl();
    this.nextUrl = this.prevUrl;
    this.selectedPlan = this.deviceService.selectedPlan;
  }

  ngAfterViewInit(): void {
    this.applePayInit();
  }

  applePayInit() {
    const stripe = Stripe(environment.STRIPE_KEY);
    const paymentRequest = stripe.paymentRequest(this.applePay.getPaymentRequestObject);
    const elements = stripe.elements();
    const prButton = elements.create('paymentRequestButton', {paymentRequest});
    (async () => {
      const result = await paymentRequest.canMakePayment();
      if (result && result.applePay) {
        prButton.mount('#payment-request-button');
      } else {
        this.renderer.setStyle(this.payment.nativeElement, 'display', 'none');
      }
    })();
    paymentRequest.on('token', (ev) => {
      this.loginService.addUserCard(ev.token.id).pipe(takeUntil(this.destroy))
        .subscribe(() => {
            this.addCardSuccess('Apple');
            ev.complete('success');
          },
          () => ev.complete('fail'));
    });
  }

  loadPaymentData() {
    this.googlePayClient.loadPaymentData(this.paymentDataRequest).then(
      (paymentData) => {
        this.addCardViaGooglePay(paymentData);
      });
  }

  addCardViaGooglePay(paymentData) {
    return new Promise(() => {
      const paymentToken = JSON.parse(paymentData.paymentMethodData.tokenizationData.token);
      this.loginService.addUserCard(paymentToken.id).pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.addCardSuccess('Google');
        });
    });
  }

  addCardSuccess(serviceName: string) {
    this.modalContent = `New card was added via ${serviceName} Pay.`;
    this.modalService.open('addCardModal');
    this.url = '/account';
    this.isSuccess = true;
  }

  cancelCard() {
    const isPricing = this.prevUrl === '/pricing';
    this.url = '/account';
    this.router.navigateByUrl(isPricing ? this.prevUrl : this.url);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    if (this.isSuccess && this.prevUrl === '/pricing' && this.selectedPlan) {
      this.modalService.open('paymentModal');
      this.isSuccess = false;
    } else if (this.nextUrl === '/devices') {
      this.router.navigateByUrl(this.nextUrl);
    } else if (!this.isSuccess && this.prevUrl === '/pricing' && this.selectedPlan) {
      this.router.navigateByUrl('/pricing');
    } else if (!this.isSuccess) {
      this.router.navigateByUrl(this.url);
    } else {
      this.router.navigateByUrl('/account');
    }
  }

  submitPayment(): void {
    this.isPaymentAgreed = true;
    this.subscription$ = this.loginService.createSubscription(this.deviceService.selectedPlan.id)
      .pipe(
        take(1),
        flatMap(() => this.loginService.getUserData()),
        flatMap(value => {
          this.isPaymentAgreed = false;
          this.loginService.setUserFullInfo = value.data;
          this.modalService.close('paymentModal');
          this.modalService.open('membershipsModalPlanSuccess');
          const internalService: any = JSON.parse(localStorage.getItem('internalService'));
          this.nextUrl = '/devices';
          this.deviceService.firstTimeCreatedRouters.push({
            routerId: internalService.routerId,
            firstTime: true,
            country: internalService.country.abbrev,
            setup_progress: true,
            timeCreated: new Date().getTime()
          });
          return this.deviceService.setupServiceSettings(internalService.routerId, internalService.serviceId, internalService.data);
        })
      )
      .subscribe(value => localStorage.removeItem('internalService'),
          error => {
        this.deviceService.firstTimeCreatedRouters.pop();
        this.isPaymentAgreed = false;
      });
  }

  submitForm(event): void {
    event.preventDefault();
    this.isPaymentAgreed = true;
    this.stripeService.createToken(this.addCardForm.value).pipe(
      takeUntil(this.destroy),
      switchMap((response: any) => this.loginService.addUserCard(response.id)),
      catchError(err => throwError(err)))
      .subscribe((data) => {
          this.isPaymentAgreed = false;
          this.modalContent = 'New card was added.';
          this.modalService.open('addCardModal');
          this.url = '/account';
          this.isSuccess = true;
          const userFullInfo = this.loginService.getUserFullInfo;
          userFullInfo.cards.push(data.data);
          this.loginService.setUserFullInfo = userFullInfo;
        },
          err => {
          this.isPaymentAgreed = false;
          this.url = '/add-card';
          this.isSuccess = false;
          if (err.status === 400) {
            this.modalContent = err.error.errors.title;
            this.modalService.open('addCardModal');
          } else if (err.status === 402) {
            this.modalContent = err.error.error.message;
            this.modalService.open('addCardModal');
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }
}
