import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountComponent} from './account.component';
import {MembershipsComponent} from './memberships/memberships.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {HelpComponent} from './help/help.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddCardComponent} from './add-card/add-card.component';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CardListComponent } from './card-list/card-list.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  spaceBetween: 20,
  centeredSlides: true
};

@NgModule({
  declarations: [
    AddCardComponent,
    AccountComponent,
    HelpComponent,
    MembershipsComponent,
    CardListComponent
  ],
  imports: [
    CreditCardDirectivesModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    SharedModule,
    SwiperModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class AccountModule {
}
