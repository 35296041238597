import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as MobileDetect from 'mobile-detect';

import {DeviceService} from '../../shared/services/device-service/device.service';
import {ModalService} from '../../shared/modal/modal.service';
import {Device} from '../../shared/models/device';

const SCAN_RESPONSE = {
  REGISTERED_EARLIER: 'router_has_been_registered_earlier',
  REGISTERED_ANOTHER_ACC: 'router_is_already_registered_with_another_account',
  NOT_REGISTERED: 'router_has_not_been_registered_yet'
};

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit, OnDestroy {

  @ViewChild('scanner')
  private scanner: ZXingScannerComponent;

  public isCameras = false;
  private hasPermission: boolean;
  public modalContent = 'For the best experience please use your smartphone camera.';
  public availableDevices: MediaDeviceInfo[] | string[];
  public selectedDevice: any;
  private destroy = new Subject();
  public isLoaded = false;
  public isDataSend: boolean;
  public mobileDetectInstance = new MobileDetect(window.navigator.userAgent);

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    public modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.isLoaded = true;

    this.scanner.camerasFound
      .pipe(takeUntil(this.destroy))
      .subscribe((devices: MediaDeviceInfo[]) => {
        if (!devices.length) return;
        this.availableDevices = this.getDevices(devices);
        this.selectedDevice = this.availableDevices.length === 1 ? this.availableDevices[0] :
          this.availableDevices.find(device => device.label === 'Back Camera') || this.availableDevices[0];
        this.isCameras = true;
      });

    this.scanner.camerasNotFound
      .pipe(takeUntil(this.destroy))
      .subscribe((devices: MediaDeviceInfo[]) => {
        this.modalContent = 'We are unable to detect your camera or webcam. For the best experience please use your smartphone camera.';
        this.modalService.open('scanNotification');
      });

    this.scanner.permissionResponse
      .pipe(takeUntil(this.destroy))
      .subscribe((answer: boolean) => this.hasPermission = answer);
  }

  onDeviceSelectChange(selectedValue: string) {
    this.selectedDevice = this.scanner.getDeviceById(selectedValue);
  }

  handleQrCodeResult(resultString: string) {
    if (this.isDataSend) return;

    this.isDataSend = true;
    const data = {scanner_output: resultString};
    const qrCodeData = {
      mac_addr: resultString.split(' ')[0],
      serial_number: resultString.split(' ')[1],
      ddns: resultString.split(' ')[2],
    };
    this.deviceService.deviceId = resultString;
    this.deviceService.checkDeviceForExistence(data).pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        this.modalContent = res.data.title;
        if (res.data.code === SCAN_RESPONSE.REGISTERED_EARLIER || res.data.code === SCAN_RESPONSE.REGISTERED_ANOTHER_ACC) {
          this.modalService.open('scanNotification');
          return;
        }
        this.deviceService.updateLocalDevice(new Device(qrCodeData));
        this.router.navigateByUrl('/add-device');
      },
        error => {
        this.modalContent = error.error.errors.title;
        this.modalService.open('scanNotification');
      });
  }

  public getDevices(devices: MediaDeviceInfo[]): MediaDeviceInfo[] {
    if (this.mobileDetectInstance.mobile() === null) this.modalService.open('scanNotification');
    if (devices[0].label.split(' ').includes('Back')) {
      return devices;
    } else if (devices[0].label.split(' ').includes('FaceTime')) {
      return devices.map(device => ({...device, ...{label: 'Front Camera'}}));
    } else if (devices[0].label.split(' ').includes('camera2')) {
      return devices.map(device => {
        console.log('android', device);
        return device.label.split(' ').includes('front') ?
          {...device, ...{label: 'Front Camera'}} : {...device, ...{label: 'Back Camera'}};
      });
    }
    return devices;
  }

  closeModal(modal: string): void {
    this.modalService.close(modal);
    this.isDataSend = false;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
