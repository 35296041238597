export class ServicesData {
  public services = [
    {name: '', logo: 'logo'},
    {name: 'Service name 2', logo: 'logo'},
    {name: 'Service name 3', logo: 'logo'},
    {name: 'Service name 4', logo: 'logo'},
    {name: 'Service name 5', logo: 'logo'},
    {name: 'Service name 6', logo: 'logo'},
  ];
}
