// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  guardState: !!(localStorage.getItem('id') && localStorage.getItem('auth')),
  apiUrl: 'https://backend-test.boundaryhopper.com/api',
  apiOrigin: 'https://backend-test.boundaryhopper.com',
  captchaKey: '6Lfb1aUUAAAAAAwV6eJV5AMRiHkqm5y9vOYMcrol',
  STRIPE_KEY: 'pk_test_S3wXVbphmZSqHNy6apSftFq4004Dio5ZMN',
  merchantId: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
