import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {flatMap, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

import {DeviceService} from '../../shared/services/device-service/device.service';
import {LoginService} from '../../shared/services/login-service/login.service';
import {ModalService} from '../../shared/modal/modal.service';
import {RouterHistoryService} from '../../shared/services/router-history/router-history.service';
import {Provider} from '../../shared/models/provider';
import {Country} from '../../shared/models/country';
import {Device} from '../../shared/models/device';
import {NickamesModel} from '../../shared/models/nickames.model';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {

  public routePath: string;
  public path: string[] = ['services'];
  public order = -1;
  public sortingType = 'name';
  public services = [];
  public isTypePassword = true;
  public device: Device;
  public providerUserId: number;
  public routerParamId: string;
  public activeProvider: Provider;
  public countries: Country[];
  public selectedCountry: Country;
  public selectError = '';
  public file: File;
  private destroy = new Subject();
  public isUploaded: boolean;
  public isFormSubmitted: boolean;
  public isPasswordVisible: boolean;
  public isDropdownOpened: boolean;
  public selectedNickname: NickamesModel;
  public nicknames: NickamesModel[];
  public useExistingClicked = false;
  public chooseFileClicked = false;
  public internalServiceLoading = false;
  public loginForm: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  public servicesForm: FormGroup = new FormGroup({
    service: new FormControl(''),
    searchText: new FormControl(''),
  });
  public uploadForm = new FormGroup({
    file: new FormControl(null, Validators.required),
    vpn_nickname: new FormControl('', Validators.required),
    vpn_username: new FormControl(''),
    vpn_password: new FormControl('')
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceService,
    private modalService: ModalService,
    private loginService: LoginService,
    private routerHistoryService: RouterHistoryService
  ) {
  }

  ngOnInit() {
    this.routerParamId = this.route.snapshot.paramMap.get('id');
    this.deviceService.getUserDevice(this.routerParamId)
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.device = value.data;
        if (!this.device.settings || !this.device.settings.change_country) return;
        this.selectedCountry = this.device.settings.change_country.country;
      });
    this.deviceService.getProviders()
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => {
        this.services = value.data;
        this.services.sort((key1, key2) => key1.name > key2.name ? 1 : -1);
      });
    this.deviceService.getCountries()
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.countries = value.data;
      });
    this.servicesForm.controls.service.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.services = this.services.map(service => ({...service, active: service.id === value}));
        this.activeProvider = this.services.find(provider => provider.active);
      });
  }

  sortTable(prop: string) {
    this.path = prop.split('.');
    this.order = this.order * (-1);
    this.sortingType = prop;
    return false;
  }

  submitForm() {
    this.deviceService.newService = this.servicesForm.value.service;
    this.modalService.isPassword = true;
  }

  resetForm(servicesForm, edit) {
    edit.classList.add('remove-edit');
    setTimeout(() => {
      servicesForm.reset();
    }, 500);
  }

  submitLoginForm() {
    this.isUploaded = true;
    this.isFormSubmitted = true;
    this.deviceService.loginService(this.loginForm.value, this.device.id, this.servicesForm.value.service)
      .pipe(
        takeUntil(this.destroy),
        flatMap(res => {
          this.providerUserId = res.data.provider_user_id;
          return this.deviceService.deselectProviderUser(this.device.id);
        }),
        flatMap(() => this.deviceService.selectService(+this.routerParamId, this.providerUserId, 1))
      )
      .subscribe(() => {
        this.isUploaded = false;
        this.selectError = '';
        this.modalService.close('addService');
        this.modalService.open('confirmService');
        this.routePath = '/devices';
        this.deviceService.externalVipDnsDevices.push({
          id: +this.routerParamId,
          isCreated: true
        });
      }, error => {
        this.isUploaded = false;
        this.isFormSubmitted = false;
        this.modalService.close('addService');
        this.selectError = 'Your credentials are not correct';
        this.modalService.open('confirmService');
        this.servicesForm.controls.service.setValue('');
        this.routePath = `/services/${this.routerParamId}`;
      });
  }

  setCountry(event) {
    this.selectedCountry = event;
  }

  submitServiceForm() {
    if (this.servicesForm.value.service === 1) this.modalService.open('addService');
    if (this.servicesForm.value.service === 2) this.modalService.open('uploadModal');
    if (this.servicesForm.value.service === 3) this.saveWithInternalService();
  }

  confirmService() {
    this.modalService.close('confirmService');
    this.router.navigateByUrl(this.routePath);
  }

  cancelService() {
    this.isFormSubmitted = false;
    this.modalService.close('addService');
    this.modalService.isPassword = false;
  }

  saveWithInternalService() {
    this.internalServiceLoading = true;
    const defaultCountryId = this.countries.find(country => country.abbrev === 'US');
    const data = {
      settings: {
        change_country: {
          country_id: defaultCountryId.id
        }
      }
    };
    if (this.selectedCountry) data.settings.change_country = {country_id: this.selectedCountry.id};
    else this.selectedCountry = defaultCountryId;

    const selectCountry = this.selectedCountry || defaultCountryId;
    this.deviceService.firstTimeCreatedRouters.push({
      routerId: +this.routerParamId,
      firstTime: false,
      country: selectCountry.abbrev,
      setup_progress: true,
      timeCreated: new Date().getTime()
    });

    this.deviceService.setupServiceSettings(this.device.id, this.servicesForm.value.service, data)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
          this.selectError = '';
          this.modalService.open('confirmService');
          this.routePath = '/devices';
        }, err => {
          this.loginService.setLocalData('internalService', {serviceId: 3, routerId: this.device.id, data});
          this.selectError = err.error.errors.title;
          this.modalService.open('confirmService');
          this.routePath = '/pricing';
        }
      );
  }

  closeUploadModal() {
    this.modalService.close('uploadModal');
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
      this.chooseFileClicked = true;
    }
  }

  uploadFile() {
    this.isUploaded = true;
    const data = {
      file: this.uploadForm.get('file').value,
      username: this.uploadForm.get('vpn_username').value,
      password: this.uploadForm.get('vpn_password').value,
      nickname: this.uploadForm.get('vpn_nickname').value,
    };
    this.deviceService.uploadFile(data, this.servicesForm.value.service, +this.routerParamId)
      .pipe(
        takeUntil(this.destroy),
        flatMap(res => {
          this.providerUserId = res.data.provider_user_id;
          return this.deviceService.deselectProviderUser(+this.routerParamId);
        }),
        flatMap(() => this.deviceService.selectService(+this.routerParamId, this.providerUserId, 2))
      )
      .subscribe(() => {
        this.deviceService.externalOpenVpnDevices.push({
          id: +this.routerParamId,
          isCreated: true
        });
        this.isUploaded = false;
        this.modalService.close('uploadModal');
        this.modalService.open('confirmService');
        this.routePath = '/devices';
      }, error => {
        this.isUploaded = false;
      });
  }

  selectExistingConfig(): void {
    let selectedProviderUser;
    this.nicknames = this.getExistingNicknames(this.device).providerUser;
    if (this.nicknames.length > 1 && this.useExistingClicked === false) {
      this.useExistingClicked = true;
      return;
    }
    const defaultProviderUser = this.nicknames[0];
    selectedProviderUser = this.selectedNickname ? this.selectedNickname : defaultProviderUser;
    this.isUploaded = true;
    this.deviceService.deselectProviderUser(+this.routerParamId)
      .pipe(
        switchMap(() => this.deviceService.selectService(+this.routerParamId, selectedProviderUser.provider_user_id, 2))
      )
      .subscribe(() => {
        this.deviceService.externalOpenVpnDevices.push({
          id: +this.routerParamId,
          isCreated: true
        });
        this.isUploaded = false;
        this.modalService.close('uploadModal');
        this.modalService.open('confirmService');
        this.routePath = '/devices';
      }, error => this.isUploaded = false);
  }

  getExistingNicknames(device): Provider {
    if (device) return device.providers.find(el => el.id === 2);
  }

  get getSearchText(): string {
    return this.servicesForm.controls.searchText.value;
  }

  getAvailableNicknames() {
    const activeProvider = this.getExistingNicknames(this.device);
    const providerUsers = activeProvider.providerUser;
    return providerUsers.filter(el => !el.is_selected);
  }

  selectNickname(nickname: NickamesModel): void {
    this.selectedNickname = nickname;
  }

  get getPrevUrl() {
    return this.routerHistoryService.getPreviousUrl();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }
}
