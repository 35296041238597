import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ModalService} from '../../shared/modal/modal.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DeviceService} from '../../shared/services/device-service/device.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {

  public isTypePassword = true;

  private addForm: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private deviceService: DeviceService,
    private location: Location,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
  }

  submitForm(formData) {
    // this.deviceService.loginService(formData).pipe(first()).subscribe(
    //   () => {
    //     this.modalService.open('confirmService');
    //   },
    //   error => console.log(error)
    // );
  }

  togglePassword() {
    this.isTypePassword = !this.isTypePassword;
  }

  closeModal() {
    this.location.back();
    this.modalService.close('confirmService');
  }
}
