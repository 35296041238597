import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {flatMap, switchMap, takeUntil} from 'rxjs/operators';

import {ModalService} from '../../shared/modal/modal.service';
import {DeviceService} from '../../shared/services/device-service/device.service';
import {AddDeviceData} from '../../shared/mocup-data/add-device-data';
import {LoginService} from '../../shared/services/login-service/login.service';
import {Device} from '../../shared/models/device';
import {Country} from '../../shared/models/country';
import {Provider} from '../../shared/models/provider';

const variables = {
  errUseServiceWithoutSubscription: 'attempt_use_of_services_without_subscription',
  emptySubscription: 'Oops, it looks like you do not have an active subscription with us! Please choose a plan.',
  maxDeviceNumberErrorCode: 'maximum_number_of_devices_reached',
  notCorrectCredentials: 'Your credentials are not correct',
  modalsId: ['confirmDevice', 'addService', 'uploadModal'],
  routes: ['/services', '/pricing', '/devices', '/add-device']
};

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceComponent implements OnInit, OnDestroy {

  @ViewChild('nameInput') input: ElementRef;
  public device: Device;
  public destroy = new Subject();
  public errorCode: string;
  public connectText: string;
  public sortingType: string;
  public deviceDescription: string;
  public services = [];
  public modelNumber: string;
  public path: string;
  public service: object;
  public showEditForm = true;
  public isTypePassword = true;
  public externalService: Provider;
  public imgPath = '';
  public selectError = '';
  public file: File;
  public countries: Country[];
  public country: Country;
  public routerId: number;
  public providerUserId: number;
  public isFormSubmitted: boolean;
  public isUploaded: boolean;
  public isPasswordVisible: boolean;
  public manageForm = new FormGroup({
    deviceName: new FormControl('', [
      Validators.required, Validators.pattern('[a-zA-Z0-9_ ]*')
    ]),
    service: new FormControl('', Validators.required),
  });
  public addForm: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  public uploadForm = new FormGroup({
    file: new FormControl(null, Validators.required),
    vpn_nickname: new FormControl('', Validators.required),
    vpn_username: new FormControl(''),
    vpn_password: new FormControl('')
  });

  constructor(
    private deviceData: AddDeviceData,
    private deviceService: DeviceService,
    private router: Router,
    private modalService: ModalService,
    private loginService: LoginService,
  ) {}

  ngOnInit() {
    this.deviceService.getProviders().pipe(takeUntil(this.destroy)).subscribe(value => {
      this.services = value.data;
      this.services.sort((key1, key2) => key1.name > key2.name ? 1 : -1);
    });
    this.modelNumber = this.deviceService.deviceId ? this.deviceService.deviceId.split(' ')[1] : '';
    this.input.nativeElement.focus();
    this.manageForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => { this.showEditForm = true; });
    this.service = this.deviceData.service;
    this.sortingType = 'date';
    this.connectText = 'Linked';
    this.deviceService.getCountries().pipe(takeUntil(this.destroy)).subscribe(value => {
      this.countries = value.data;
    });
  }

  setCountry(event) {
    this.country = event;
  }

  submitForm() {
    if (!this.manageForm.value.service || this.manageForm.value.service === 'no-service') {
      this.saveWithoutService();
    } else if (this.manageForm.value.service !== 2 && this.manageForm.value.service !== 3) {
        this.externalService = this.services.filter(service => service.id !== 2 && service.id !== 3)
          .find(service => service.id = this.manageForm.value.service);
        this.imgPath = this.externalService.image;
        this.modalService.open('addService');
        this.showEditForm = false;
    } else if (this.manageForm.value.service === 2) {
      this.modalService.open('uploadModal');
    } else if (this.manageForm.value.service === 3) {
      this.saveWithInternalService();
    }
  }

  saveDevice() {
    this.modalService.close('confirmDevice');
    if (this.errorCode === variables.errUseServiceWithoutSubscription) {
      this.router.navigateByUrl(variables.routes[1]);
      return;
    }
    this.router.navigateByUrl(variables.routes[2]);
  }

  resetForm(edit) {
    edit.classList.add('remove-edit');
    setTimeout(() => this.manageForm.reset(''), 500);
    this.showEditForm = false;
  }

  addService() {
    this.router.navigateByUrl(variables.routes[0]);
  }

  submitServiceForm() {
    this.isUploaded = true;
    this.isFormSubmitted = true;
    this.deviceService.addDevice(this.manageForm.value)
      .pipe(
        takeUntil(this.destroy),
        flatMap(res => {
          this.routerId = res.data.id;
          return this.deviceService.loginService(this.addForm.value, this.routerId, this.manageForm.value.service);
        }),
        flatMap(res => {
          this.providerUserId = res.data.provider_user_id;
          return this.deviceService.selectService(this.routerId, this.providerUserId, 1);
        })
      )
      .subscribe(() => {
        this.deviceService.externalVipDnsDevices.push({
          id: this.routerId,
          isCreated: true
        });
        this.isUploaded = false;
        this.path = variables.routes[2];
        this.modalService.close(variables.modalsId[1]);
        this.modalService.open(variables.modalsId[0]);
        this.modalService.isPassword = false;
        }, () => {
        this.isUploaded = false;
        this.modalService.close(variables.modalsId[1]);
        this.selectError = variables.notCorrectCredentials;
        this.path = variables.routes[3];
        this.modalService.open(variables.modalsId[0]);
        this.manageForm.controls.service.setValue('');
      });
  }

  cancelService(route) {
    this.modalService.close('addService');
    this.modalService.isPassword = false;
    this.router.navigateByUrl(route);
  }

  closeUploadModal() {
    this.modalService.close('uploadModal');
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  uploadFile() {
    this.isUploaded = true;
    const { file, vpn_username: username, vpn_password: password, vpn_nickname: nickname } = this.uploadForm.value;
    const uploadUserData = { file, username, password, nickname };
    this.deviceService.addDevice(this.manageForm.value)
      .pipe(
        takeUntil(this.destroy),
        flatMap(res => {
          this.routerId = res.data.id;
          return this.deviceService.uploadFile(uploadUserData, this.manageForm.value.service, this.routerId);
        }),
        flatMap(res => this.deviceService.selectService(this.routerId, res.data.provider_user_id, 2))
      )
      .subscribe(() => {
        this.deviceService.externalOpenVpnDevices.push({
          id: this.routerId,
          isCreated: true
        });
        this.isUploaded = false;
        this.modalService.close(variables.modalsId[2]);
        this.modalService.open(variables.modalsId[0]);
        this.path = variables.routes[2];
      }, error => {
        this.isUploaded = false;
        this.selectError = error.error.errors.title;
        this.modalService.close(variables.modalsId[2]);
        this.modalService.open(variables.modalsId[0]);
      });
  }

  saveWithoutService() {
    this.deviceService.addDevice(this.manageForm.value)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.modalService.open(variables.modalsId[0]);
        this.path = variables.routes[2];
      }, error => {
        this.selectError = error.error.errors.title;
        this.modalService.open(variables.modalsId[0]);
      });
    this.showEditForm = false;
  }

  saveWithInternalService() {
    const defaultCountryId = this.countries.find(country => country.abbrev === 'US');
    const data = {
      settings: {
        change_country: {
          country_id: defaultCountryId.id
        }
      }
    };
    if (this.country) data.settings.change_country = {country_id: this.country.id};
    else this.country = defaultCountryId;
    this.deviceService.addDevice(this.manageForm.value).pipe(takeUntil(this.destroy),
      switchMap(res => {
        this.routerId = res.data.id;
        this.deviceService.firstTimeCreatedRouters.push({
          routerId: this.routerId,
          firstTime: true,
          country: this.country.abbrev,
          setup_progress: true,
          timeCreated: new Date().getTime()
        });
        return this.deviceService.setupServiceSettings(this.routerId, 3, data);
      }))
      .subscribe(() => {
        this.modalService.open(variables.modalsId[0]);
        this.path = variables.routes[2];
      }, err => {
        err.status === 403 ? this.selectError = variables.emptySubscription : this.selectError = err.error.errors.title;
        this.errorCode = err.error.errors.code;
        this.modalService.open(variables.modalsId[0]);
        this.path = variables.routes[1];
        this.deviceService.firstTimeCreatedRouters.pop();
        if (this.errorCode === variables.maxDeviceNumberErrorCode) return;
        this.loginService.setLocalData('internalService', {serviceId: 3, routerId: this.routerId, data, country: this.country});
      });
    this.showEditForm = false;
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }
}


