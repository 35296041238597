import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {LoginService} from '../../shared/services/login-service/login.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {

  public isTypePassword: boolean;
  private responseState: any;
  private signInSubscription$: Subscription;
  public wrongData: boolean;
  public minInputLength = 8;
  public maxInputLength = 20;
  public isSubmitted: boolean;
  public destroy = new Subject();

  public signInForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
    ]),
    password: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)
    ]),
  });

  constructor(private authService: AuthService, private router: Router, private login: LoginService) {
    this.isTypePassword = true;
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.signInSubscription$) {
      this.signInSubscription$.unsubscribe();
    }
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {
        this.login.socialLogin('facebook', userData.authToken).subscribe(
          response => {
            localStorage.setItem('auth', response.data.access_token);
            localStorage.setItem('id', response.data.user_id);
            this.login.isAuthenticated = true;
            this.router.navigateByUrl('/devices');
          },
            error => console.log(error)
        );
      }    );
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData) => {
        this.login.socialLogin('google', userData.authToken).subscribe(
          response => {
            localStorage.setItem('auth', response.data.access_token);
            localStorage.setItem('id', response.data.user_id);
            this.login.isAuthenticated = true;
            this.router.navigateByUrl('/devices');
          },
            error => console.log(error)
        );
      }
    );
  }

  togglePassword() {
    this.isTypePassword = !this.isTypePassword;
  }

  submitForm(loginForm) {
    this.isSubmitted = true;
    this.signInSubscription$ = this.login.signIn(loginForm.value)
      .pipe(takeUntil(this.destroy))
      .subscribe(response => {
        this.isSubmitted = false;
        this.wrongData = false;
        localStorage.setItem('auth', response.data.access_token);
        localStorage.setItem('id', response.data.user_id);
        this.responseState = response;
        this.login.isAuthenticated = true;
        this.router.navigateByUrl('/devices');
      },
        error => {
        this.isSubmitted = false;
        if (error.status === 401 || error.status ===  422) {
          this.signInForm.reset(this.signInForm.value);
          this.wrongData = true;
        }
      }
    );
  }
}


