import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {ModalService} from '../../shared/modal/modal.service';
import {DeviceService} from '../../shared/services/device-service/device.service';
import {Provider} from '../../shared/models/provider';
import {Country} from '../../shared/models/country';
import {Device} from '../../shared/models/device';
import {NickamesModel} from '../../shared/models/nickames.model';

@Component({
  selector: 'app-manage-device',
  templateUrl: './manage-device.component.html',
  styleUrls: ['./manage-device.component.scss']
})
export class ManageDeviceComponent implements OnInit, OnDestroy {

  public isFetched: boolean;
  public isVisible: boolean;
  public countries: Country[] = [];
  public country: Country;
  public devices: Device[];
  public device: Device;
  public nicknames: NickamesModel[];
  public routerParamId: string;
  public selectError: string;
  public serviceInternal = false;
  public destroy = new Subject();
  public service: object;
  public openSubmit: boolean;
  public isServiceSelected: boolean;
  public isDeleted: boolean;
  public isPasswordVisible: boolean;
  public isDropdownOpened: boolean;
  public isNicknameSelected: boolean;
  public isWifiPasswordVisible = true;
  public activeProvider: Provider;
  public deviceForm: FormGroup = new FormGroup({
    deviceName: new FormControl('', [Validators.required, Validators.maxLength(16)]),
    wifiPassword: new FormControl('', Validators.maxLength(20)),
    ssid: new FormControl('', Validators.maxLength(30))
  });
  public uploadForm = new FormGroup({
    file: new FormControl(null, Validators.required),
    vpn_nickname: new FormControl('', Validators.required),
    vpn_username: new FormControl(''),
    vpn_password: new FormControl('')
  });

  constructor(
    private deviceService: DeviceService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.routerParamId = this.route.snapshot.paramMap.get('id');
    this.deviceService.getUserDevice(this.routerParamId)
      .pipe(takeUntil(this.destroy))
      .subscribe(response => {
        const activeProvider = this.getActiveProvider(response.data);
        this.device = response.data;
        this.deviceForm.patchValue({
          deviceName: this.device.label,
          wifiPassword: this.device.wifi_password || 'goodlife',
          ssid: this.device.wifi_ssid
        });
        if (activeProvider) this.nicknames = activeProvider.providerUser;
        this.isFetched = true;
        this.ifServiceProviderSelected();
      });
    this.deviceService.getCountries()
      .pipe(takeUntil(this.destroy))
      .subscribe(countries => this.countries = countries.data);
  }

  ifServiceProviderSelected() {
    this.activeProvider = this.device.providers.find(provider => provider.active === true);
    if (this.activeProvider) this.isServiceSelected = true;
    this.serviceInternal = this.activeProvider ? this.activeProvider.name
      .toLowerCase().includes('boundary') : false;
  }

  setCountry(event): void {
    const data = {
      settings: {
        change_country: {
          country_id: event.id
        }
      }
    };
    this.deviceService.firstTimeCreatedRouters.push({
      routerId: this.device.id,
      firstTime: false,
      country: event.abbrev,
      setup_progress: true,
      timeCreated: new Date().getTime()
    });

    this.deviceService.setupServiceSettings(this.device.id, 3, data)
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.country = event;
        this.device.providers.find(obj => obj.active === true).providerUser = value.data;
      }, error => {
        this.deviceService.firstTimeCreatedRouters.pop();
        this.isVisible = true;
        this.openModal('countryLimitModal');
      });
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.isVisible = false;
  }

  submitForm() {
    this.deviceService.updateDevice(this.routerParamId,  this.deviceForm.value)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => this.router.navigateByUrl('/devices'));
  }

  deleteDevice() {
    this.isDeleted = true;
    const index = this.deviceService.firstTimeCreatedRouters
      .findIndex(obj => obj.routerId === this.routerParamId);
    this.deviceService.firstTimeCreatedRouters.splice(index, 1);
    this.deviceService.deleteDevice(this.routerParamId)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
          this.modalService.close('confirmDelete');
          this.isDeleted = false;
          this.router.navigateByUrl('/devices');
        }
      );
  }

  resetForm(manageForm, edit) {
    edit.classList.add('remove-edit');
    setTimeout(() => {
      this.openSubmit = false;
      manageForm.reset(this.deviceService.deviceData);
    }, 500);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }

  uploadFile() {
    this.isVisible = true;
    const uploadUserData = {
      file: this.uploadForm.get('file').value,
      username: this.uploadForm.get('vpn_username').value,
      password: this.uploadForm.get('vpn_password').value,
      nickname: this.uploadForm.get('vpn_nickname').value
    };
    this.deviceService.uploadFile(uploadUserData, 2, +this.routerParamId)
      .pipe(
        takeUntil(this.destroy),
        switchMap(res => this.deviceService.selectService(+this.routerParamId, res.data.provider_user_id, 2))
      )
      .subscribe(() => {
        this.isVisible = false;
        this.modalService.close('uploadModal');
        this.router.navigateByUrl('/devices');
        this.deviceService.externalOpenVpnDevices.push({
          id: +this.routerParamId,
          isCreated: true
        });
      }, error => {
        this.isVisible = false;
        this.selectError = error.error.errors.title;
        this.modalService.close('uploadModal');
      });
  }

  getActiveProvider(device: Device): Provider {
    return device.providers.find(item => item.active);
  }

  getSelectedNickname(device: Device) {
    const activeProvider = this.getActiveProvider(device).providerUser;
    return activeProvider.find(el => el.is_selected);
  }

  getAvailableNicknames(device: Device) {
    const activeProvider = this.getActiveProvider(device);
    const providerUsers = activeProvider.providerUser;
    return providerUsers.filter(el => !el.is_selected);
  }

  selectNickname(nickname: NickamesModel): void {
    this.isNicknameSelected = true;
    this.deviceService.externalOpenVpnDevices.push({
      id: +this.routerParamId,
      isCreated: true
    });
    this.deviceService.deselectProviderUser(+this.routerParamId)
      .pipe(
        takeUntil(this.destroy),
        switchMap(() => this.deviceService.selectService(+this.routerParamId, nickname.provider_user_id, 2))
      )
      .subscribe(() => this.isNicknameSelected = false);
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

}
