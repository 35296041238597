import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AccountModule} from './account/account.module';
import {DeviceModule} from './device/device.module';
import {LoginModule} from './login/login.module';
import {ModalService} from './shared/modal/modal.service';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import {LoginService} from './shared/services/login-service/login.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {SharedModule} from './shared/shared.module';
import {StripeService} from './shared/services/stripe-service/stripe.service';
import {DeviceService} from './shared/services/device-service/device.service';
import {MembershipsData} from './shared/mocup-data/memberships-data';
import {AddDeviceData} from './shared/mocup-data/add-device-data';
import {ManageDeviceData} from './shared/mocup-data/manage-device-data';
import {ServicesData} from './shared/mocup-data/services-data';
import {JwtInterceptor} from './interceptor/jwt.interceptor';
import {CardsService} from './shared/services/cards-service/cards.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    HttpClientModule,
    CreditCardDirectivesModule,
    LoginModule,
    DeviceModule,
    AccountModule,
    AppRoutingModule,
    BrowserModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    environment.production ? ServiceWorkerModule.register('ngsw-worker.js') : []
  ],
  providers: [
    ModalService,
    LoginService,
    LoginComponent,
    StripeService,
    DeviceService,
    HttpClientModule,
    MembershipsData,
    AddDeviceData,
    ManageDeviceData,
    ServicesData,
    CardsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
