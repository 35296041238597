import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, merge, fromEvent, of} from 'rxjs';
import {map, take} from 'rxjs/operators';

import {Device} from '../../models/device';
import {Plan} from '../../models/plan';
import {API} from '../../http/api-variables';

interface UploadForm {
  file: File;
  username: string;
  nickname: string;
  password: string;
}

const includeParam = 'settings,providers,providers.providerUser,providers.active,online,dyndns_current,geodata,openvpn_status,wgserver_status,wireguard_status,setup_completed';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private httpHeaders: HttpHeaders;
  private device = new BehaviorSubject(new Device({}));
  public month = new BehaviorSubject(true);
  public deviceId: string;
  public deviceData: {
    id: any;
    providers: any;
  };
  public newService: {
    id: number;
    name: string,
    ur: string
  };
  checkedState: boolean;
  public selectedPlan: Plan;
  public firstTimeCreatedRouters = [];
  public externalOpenVpnDevices = [];
  public externalVipDnsDevices = [];
  public devicesData: Device[];

  constructor(private http: HttpClient) {}

  addDevice(value) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers`;
    const data = {
      label: value.deviceName,
      scanner_output: this.deviceId,
      description: value.description
    };
    return this.http.post(url, data) as Observable<any>;
  }

  checkDeviceForExistence(data): Observable<any> {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/all/check-for-existence`;
    return this.http.post<Observable<any>>(url, data);
  }

  getUserDevice(routerId): Observable<any> {
    const myParams = new HttpParams().append('include', includeParam);
    const link = `${API.API_ROUTERS}/${routerId}`;
    return this.http.get<any>(link, {headers: this.httpHeaders, params: myParams});
  }

  getUserDevices() {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}`;
    const link = `${url}/routers`;
    const myParams = new HttpParams().append('include', includeParam);
    return this.http.get(link, {headers: this.httpHeaders, params: myParams}) as Observable<any>;
  }

  getProviders() {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/providers?include=providerUser`;
    return this.http.get(url) as Observable<any>;
  }

  uploadFile(data: UploadForm, serviceId: number, routerId: number) {
    const formData: FormData = new FormData();
    formData.append('file', data.file, data.file.name);
    formData.append('username', data.username);
    formData.append('password', data.password);
    formData.append('nickname', data.nickname);
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/${routerId}/providers/${serviceId}/provideruser/create`;
    return this.http.post(url, formData) as Observable<any>;
  }

  updateDevice(id, deviceData) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/${id}`;
    const data = {
      label: deviceData.deviceName,
      description: deviceData.deviceDescription,
      wifi_password: deviceData.wifiPassword,
      wifi_ssid: deviceData.ssid
    };
    return this.http.put(url, data) as Observable<any>;
  }

  deleteDevice(id) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/${id}`;
    return this.http.delete(url) as Observable<any>;
  }

  loginService(userData, deviceId: number, serviceId: number) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/${deviceId}/providers/${serviceId}/provideruser/create`;
    const formData: FormData = new FormData();
    formData.append('username', userData.login);
    formData.append('password', userData.password);
    return this.http.post(url, formData) as Observable<any>;
  }

  public updateLocalDevice(data: Device): void {
    this.device.next(data);
  }

  public getCountries() {
    return this.http.get(`${API.API_COUNTRIES}`) as Observable<any>;
  }

  public selectService(routerId: number, providerUserId: number, providerId: number) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/${routerId}/providers/${providerId}/provideruser/select`;
    return this.http.post(url, {provider_user_id: providerUserId}) as Observable<any>;
  }

  public deselectProviderUser(routerId: number) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/routers/${routerId}/provideruser/deselect`;
    return this.http.get(url);
  }

  public setupServiceSettings(routerId: number, serviceId: number, settings: object) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/providers/${serviceId}/routers/${routerId}/setup`;
    return this.http.post(url, settings) as Observable<any>;
  }

  public deleteUserSubscription(subscription: number) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/subscriptions/${subscription}`;
    return this.http.delete(url);
  }

  public isDeviceOnline() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      of(navigator.onLine).pipe(take(1)));
  }
}
