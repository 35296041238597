import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {LoginService} from '../services/login-service/login.service';
import {ModalService} from '../modal/modal.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentLink: string;
  public isDeviceUrl: boolean;
  public devicesIcon = '../../../assets/svg/device_new_icon.svg';
  public activeDevicesIcon = '../../../assets/svg/device_new_icon_green.svg';
  public deviceUrlArray = ['/devices', '/add-device', '/manage-device', '/add-service', 'services'];

  constructor(private router: Router, public service: LoginService, public modalService: ModalService) {
    this.currentLink = this.router.url;
    this.isDeviceUrl = this._isDeviceUrl;
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentLink = this.router.url;
        this.isDeviceUrl = this._isDeviceUrl;
      }
    });
  }

  get _isDeviceUrl(): boolean {
    return this.deviceUrlArray.includes(this.currentLink);
  }

  goToDevice() {
      this.router.navigateByUrl('/devices');
  }
}
