import {Component, OnInit} from '@angular/core';

import {VERSION} from '../../../environments/version';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public version = VERSION;

  constructor() {
  }

  ngOnInit() {
  }

}
