export class MembershipsData {
  public cards = [
    {
      isCurrent: true,
      title: 'Bronze',
      mark: false,
      save: 36,
      price: 6.97,
      time: 'month',
      offers: [
        'Netflix / Hulu / Prime +100 Other Channels',
        'Be one of 10 using the DNS box',
        'Fast Streaming Speeds (HD)',
        'Watch on Unlimited Devices',
        '30 Day Money Back Guarantee',
        'Cancel Anytime!',
        'Online Support',
        'Completely Anonymous'
      ]
    },
    {
      isCurrent: false,
      title: 'Silver',
      mark: false,
      save: 36,
      price: 6.97,
      time: 'month',
      offers: [
        'Netflix / Hulu / Prime +100 Other Channels',
        'Be one of 10 using the DNS box',
        'Fast Streaming Speeds (HD)',
        'Watch on Unlimited Devices',
        '30 Day Money Back Guarantee',
        'Cancel Anytime!',
        'Online Support',
        'Completely Anonymous'
      ]
    },
    {
      isCurrent: false,
      title: 'Gold',
      mark: true,
      save: 36,
      price: 6.97,
      time: 'month',
      offers: [
        'Netflix / Hulu / Prime +100 Other Channels',
        'Be one of 10 using the DNS box',
        'Fast Streaming Speeds (HD)',
        'Watch on Unlimited Devices',
        '30 Day Money Back Guarantee',
        'Cancel Anytime!',
        'Online Support',
        'Completely Anonymous'
      ]
    },

  ];
}

