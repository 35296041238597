import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

import { ModalService } from 'src/app/shared/modal/modal.service';
import { DeviceService } from 'src/app/shared/services/device-service/device.service';
import { LoginService } from 'src/app/shared/services/login-service/login.service';
import { StripeService } from 'src/app/shared/services/stripe-service/stripe.service';
import { Card } from 'src/app/shared/models/cards';

const TRIAL = {
  HAS_EXPIRED: 'Your Trial Has Expired',
  ENDS_IN: 'Your trial ends ',
};

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @ViewChild('notification') notificationEL: ElementRef;

  public cards: Card[];
  public accountData: any;
  public isChangePassword = true;
  public isChangeNewPassword = true;
  public isChangePasswordConfirm = true;
  public isModalAlertType = false;
  public isModalConfirmType = false;
  public modalContent: string;
  public trialDaysStatus = '';
  public subscriptionRenewalDate: string;
  public minInputLength = 8;
  public maxInputLength = 20;
  public isLoaded = false;
  public destroy = new Subject();
  public isDeleteConfirmed: boolean;
  public accountForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.pattern('^[A-Za-z_ ]+'), Validators.required]),
    email: new FormControl('', [
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
      Validators.required
    ]),
  });

  public passwordChange: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)
    ]),
    newPassword: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)
    ]),
    confirmPassword: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)
    ]),
  });

  constructor(private router: Router,
              private modalService: ModalService,
              private stripeService: StripeService,
              private loginService: LoginService,
              private deviceService: DeviceService) {
  }

  ngOnInit() {
    this.loginService.getUserData()
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.setStartData(value.data);
        this.isLoaded = true;
      });
  }

  submitForm(event) {
    event.preventDefault();
    this.loginService.updateUserData(this.accountForm.value)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.notificationEL.nativeElement.classList.add('remove-edit');
        this.router.navigateByUrl('/account');
      }
    );
  }

  openDeleteModal(id) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  clearFirstCreatedRouters(): void {
    this.deviceService.firstTimeCreatedRouters = [];
  }

  logout() {
    this.clearFirstCreatedRouters();
    this.loginService.logout()
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.router.navigateByUrl('/login');
        localStorage.clear();
      }
    );
  }

  deleteAccount() {
    if (this.isDeleteConfirmed) return;
    this.isDeleteConfirmed = true;
    this.clearFirstCreatedRouters();
    this.deviceService.devicesData = [];
    this.loginService.deleteAccount()
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.isDeleteConfirmed = false;
        this.modalService.close('deleteAccount');
        this.router.navigateByUrl('/login');
        localStorage.clear();
      }
    );
  }

  resetForm(accountForm) {
    this.notificationEL.nativeElement.classList.add('remove-edit');
    setTimeout(() => {
      accountForm.reset(this.accountData);
    }, 500);
  }

  changePassword() {
    this.modalService.isPassword = true;
    this.modalService.open('changePassword');
  }

  submitPassword(event) {
    event.preventDefault();
    const {password, newPassword} = this.passwordChange.value;
    this.loginService.changePassword({password, newPassword}).subscribe(
      () => {
        this.modalService.close('changePassword');
        this.modalContent = 'Password changed';
        this.isModalAlertType = true;
        this.modalService.open('accountModal');
        this.passwordChange.reset();
      },
  error => {
        this.isModalAlertType = true;
        this.modalContent = error.error.errors.old_password ? 'Current password is not correct' :
          'The new password must be different from the old';
        this.modalService.open('accountModal');
      }
    );
  }

  closeChangePasswordModal(route) {
    this.modalService.close('changePassword');
    this.modalService.isPassword = false;
    this.router.navigateByUrl(route);
  }

  setStartData(data) {
    this.accountData = data;
    const { subscription_renewal_date } = data;

    if (moment(subscription_renewal_date).isAfter(new Date())) {
      this.subscriptionRenewalDate = moment(data.subscription_renewal_date).format('MMMM Do YYYY');
    }

    this.loginService.setUserFullInfo = data;
    Object.keys(this.accountForm.controls).forEach(controlName =>
      this.accountForm.get(controlName).setValue(this.accountData[controlName]));
    this.cards = data.cards;

    if (data.trial_ends_at === null) return;

    const trialEndDate = moment(data.trial_ends_at);

    if (moment().to(data.trial_ends_at).includes('in ')) {
      this.trialDaysStatus = TRIAL.ENDS_IN + moment().to(trialEndDate);
    } else {
      this.trialDaysStatus = TRIAL.HAS_EXPIRED;
    }
  }

  isInvalid(formControlName: string): boolean {
    return !!this.accountForm.get(formControlName).errors;
  }

  isMatchingPasswords(confirm: string, newPassword: string): boolean {
    return (this.passwordChange.get(confirm).touched &&
      this.passwordChange.get(confirm).value !== this.passwordChange.get(newPassword).value);
  }

  public cancelPlan(): void {
    this.isDeleteConfirmed = true;
    const subscriptionId = this.accountData.subscription.id;
    this.deviceService.deleteUserSubscription(subscriptionId)
      .subscribe(() => {
        this.accountData.subscription = null;
        this.setStartData(this.accountData);
        this.isDeleteConfirmed = false;
        this.closeModal('cancelPLanModal');
      }, error => this.isDeleteConfirmed = false);
  }
}
