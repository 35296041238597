export class UserData {
  name?: string;
  email?: string;
  password?: string;
  address?: string;

  constructor(userData) {
    if (userData.name) {
      this.name = userData.name;
    }
    if (userData.email) {
      this.email = userData.email;
    }
    if (userData.password) {
      this.password = userData.password;
    }
    if (userData.address) {
      this.address = userData.address;
    }
  }
}
