import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {noop, Observable, Subject, Subscription, timer} from 'rxjs';
import {mergeMapTo, retry, switchMap, takeUntil, takeWhile, tap} from 'rxjs/operators';

import {DeviceService} from '../shared/services/device-service/device.service';
import {LoginService} from '../shared/services/login-service/login.service';
import {ModalService} from '../shared/modal/modal.service';
import {Device} from '../shared/models/device';
import {Country} from '../shared/models/country';
import {NickamesModel} from '../shared/models/nickames.model';

const animationTimeFirtsState = 'Thanks for your patience, hopping isnt that easy!';
const animationTimeLastState = 'This is taking longer than usual, something might be up';
const animationNeverCompleted = 'There was an issue switching countries, restart your device and try again.';

interface CreatedRouter {
  firstTime: boolean;
  routerId: number;
  country: string;
  setup_progress: boolean;
  animationText: string;
  animationTime: number;
  timeCreated: any;
}

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit, OnDestroy {

  private destroy = new Subject();
  public country: Country;
  public countries: Country[];
  public fetchedDeviceData: Device[];
  public devices: Device[];
  public createdRouters: CreatedRouter[];
  public services = [];
  public interval$ = timer(30000, 30000);
  public timer$: Observable<number> = timer(5000, 10000);
  public copyTimer$ = timer(5000);
  public isFetched: boolean;
  public isUpdating: boolean;
  public isDropdownOpened: boolean;
  public isNicknameSelected: boolean;
  public currentPlan = '';
  public subscriptions: Subscription = new Subscription();
  public switchingToNickname = '';
  public openVPNInProgress = false;
  private openVpnErrorCounter = 0;

  constructor(
    private deviceService: DeviceService,
    private loginService: LoginService,
    private modalService: ModalService,
    private router: Router,
    private renderer: Renderer2
  ) {
  }

  ngOnInit() {
    if (this.deviceService.devicesData) this.setDevicesData(this.deviceService.devicesData);
    // this.getUserDevices();

    this.deviceService.isDeviceOnline()
      .pipe(takeUntil(this.destroy))
      .subscribe((isOnline: boolean) => {
        if (isOnline) this.getUserDevices();
        // this.setDevicesData(this.deviceService.devicesData);
      });

    this.interval$
      .pipe(
        takeUntil(this.destroy),
        switchMap(() => this.deviceService.getUserDevices())
      )
      .subscribe(response => this.fetchedDeviceData = response.data);

    this.deviceService.getCountries()
      .pipe(takeUntil(this.destroy))
      .subscribe(countries => this.countries = countries.data);

    this.fetchOpenVpnDeviceData();
    this.fetchVipDnsDeviceData();
  }

  setDevicesData(data?): void {
    this.deviceService.devicesData = data;
    this.devices = this.deviceService.devicesData;
    this.fetchedDeviceData = this.devices;
    this.createdRouters = this.deviceService.firstTimeCreatedRouters;
    this.fetchDeviceData(this.createdRouters);
    this.isFetched = true;
  }

  getUserDevices(): void {
    this.deviceService.getUserDevices().pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.setDevicesData(value.data);
        this.isUpdating = true;
      });
  }

  fetchDeviceData(array: CreatedRouter[]) {
    if (!array.length) return;
    array.forEach((element, index) => {
      this.subscriptions.add(
        this.timer$.pipe(
          tap(() => {
            const oldDate = this.deviceService.firstTimeCreatedRouters[index].timeCreated;
            const currentDate = new Date().getTime();
            this.deviceService.firstTimeCreatedRouters[index].animationTime = ((currentDate - oldDate) / 1000) - 5;

            if (this.deviceService.firstTimeCreatedRouters[index].animationTime >= 60)
              this.createdRouters[index].animationText = animationTimeFirtsState;

            if (this.deviceService.firstTimeCreatedRouters[index].animationTime >= 120)
              this.createdRouters[index].animationText = animationTimeLastState;

            if (this.deviceService.firstTimeCreatedRouters[index].animationTime >= 150) {
              this.showWGError();
              this.createdRouters[index].animationText = animationNeverCompleted;
              this.createdRouters[index].setup_progress = false;
              this.closeSubscription(index);
            }
          }),
          mergeMapTo(this.deviceService.getUserDevice(element.routerId)),
          takeWhile(res => {
            return !(res.data.wireguard_status === 'online' && res.data.setup_completed === true);
          }),
          retry(15)
        ).subscribe(noop, () => {
          this.createdRouters[index].setup_progress = false;
          this.closeSubscription(index);
          this.showWGError();
        }, () => {
          this.createdRouters[index].setup_progress = false;
          this.closeSubscription(index);
        })
      );
    });
  }

  fetchOpenVpnDeviceData() {
    const arr = this.deviceService.externalOpenVpnDevices;
    if (!arr.length) return;
    arr.forEach((element, index) => {
      this.subscriptions.add(
        this.timer$.pipe(
          mergeMapTo(this.deviceService.getUserDevice(element.id)),
          takeWhile(res => {
            this.handleErrorsOnUpdate(index);
            return !(res.data.setup_completed === true && res.data.openvpn_status === 'online');
          })
        ).subscribe(noop, () => {
          this.createdRouters[index].setup_progress = false;
          this.closeSubscription(index);
        }, () => {
          this.getUserDevices();
          this.openVPNInProgress = false;
          this.openVpnErrorCounter = 0;
          this.deviceService.externalOpenVpnDevices[index].isCreated = false;
          this.deviceService.externalOpenVpnDevices.splice(index, 1);
        })
      );
    });
  }

  handleErrorsOnUpdate(subscriptionIndex: number) {
    this.openVpnErrorCounter++;
    if (this.openVpnErrorCounter > 10) {
      this.openVpnErrorCounter = 0;
      this.modalService.open('openVpnFileErrorModal');
      this.deviceService.externalOpenVpnDevices[subscriptionIndex].isCreated = false;
      this.closeSubscription(subscriptionIndex);
    }
  }

  private showWGError() {
    this.modalService.open('wgErrorModal');
  }

  fetchVipDnsDeviceData() {
    const arr = this.deviceService.externalVipDnsDevices;
    if (!arr.length) return;
    arr.forEach((elem, index) => {
      this.subscriptions.add(
        this.timer$.pipe(
          mergeMapTo(this.deviceService.getUserDevice(elem.id)),
          takeWhile(res => res.data.setup_completed === false)
        ).subscribe(noop, noop, () => {
          this.deviceService.externalVipDnsDevices[index].isCreated = false;
          this.deviceService.externalVipDnsDevices.splice(index, 1);
        })
      );
    });
  }

  setCountry(event, index) {
    const data = {
      settings: {
        change_country: {
          country_id: event.id
        }
      }
    };

    this.deviceService.firstTimeCreatedRouters.push({
      routerId: this.devices[index].id,
      firstTime: false,
      country: event.abbrev,
      setup_progress: true,
      timeCreated: new Date().getTime()
    });
    this.createdRouters = this.deviceService.firstTimeCreatedRouters;

    this.deviceService.setupServiceSettings(this.devices[index].id, 3, data).pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.country = event;
        this.fetchDeviceData(this.createdRouters);
      }, error => {
        this.devices[index].setup_progress = false;
        this.modalService.open('countryLimitModal');
        const elemId = this.createdRouters.findIndex(elem => elem.routerId === this.devices[index].id);
        this.closeSubscription(elemId);
      });
  }

  closeSubscription(index: number): void {
    this.deviceService.firstTimeCreatedRouters.splice(index, 1);
    this.createdRouters = this.deviceService.firstTimeCreatedRouters;
  }

  updateDevice(index: number): void {
    this.router.navigateByUrl(`/manage-device/${index}`);
  }

  getActiveProvider(device: any): any {
    return device.providers.find(provider => provider.active);
  }

  getSelectedNickname(device: Device) {
    const activeProvider = this.getActiveProvider(device).providerUser;
    return activeProvider.find(el => el.is_selected);
  }

  getAvailableNicknames(device: Device) {
    const activeProvider = this.getActiveProvider(device);
    const providerUsers = activeProvider.providerUser;
    return providerUsers.filter(el => !el.is_selected);
  }

  checkDeviceOnline(active: boolean) {
    return active === true;
  }

  getItemProgress(item: Device) {
    if (this.createdRouters.length) return this.createdRouters.find(obj => obj.routerId === item.id);
  }

  getOpenVpnItemProgress(item: Device) {
    if (this.deviceService.externalOpenVpnDevices.length)
      return this.deviceService.externalOpenVpnDevices.find(obj => obj.id === item.id);
  }

  getVipDnsItemProgress(item: Device) {
    if (this.deviceService.externalVipDnsDevices.length)
      return this.deviceService.externalVipDnsDevices.find(obj => obj.id === item.id);
  }

  copyDeviceDns(event: Event, dns: string, index: number): void {
    event.stopPropagation();
    this.devices[index].isCopied = true;
    const el = this.renderer.createElement('textarea');
    el.value = dns;
    this.renderer.setAttribute(el, 'readonly', '');
    this.renderer.setStyle(el, 'position', 'absolute');
    this.renderer.setStyle(el, 'left', '-9999px');
    this.renderer.appendChild(document.body, el);
    el.select();
    document.execCommand('copy');
    this.renderer.removeChild(document.body, el);
    this.copyTimer$.pipe(takeUntil(this.destroy)).subscribe(() => this.devices[index].isCopied = false);
  }

  closeModal(modalId: string): void {
    this.modalService.close(modalId);
  }

  selectNickname(nickname: NickamesModel, device: Device): void {
    this.switchingToNickname = nickname.nickname;
    this.openVPNInProgress = true;
    this.isNicknameSelected = true;
    this.deviceService.externalOpenVpnDevices.push({
      id: device.id,
      isCreated: true
    });
    this.fetchOpenVpnDeviceData();
    this.forceNicknameChange(device, nickname);
    this.deviceService.deselectProviderUser(device.id)
      .pipe(
        takeUntil(this.destroy),
        switchMap(() => this.deviceService.selectService(device.id, nickname.provider_user_id, 2))
      )
      .subscribe(() => this.isNicknameSelected = false);
  }

  private forceNicknameChange(device: Device, nickname: NickamesModel) {
    const ret2: NickamesModel = this.getSelectedNickname(device);
    ret2.nickname = nickname.nickname;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.subscriptions.unsubscribe();
  }

}
