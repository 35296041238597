import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DeviceService} from '../services/device-service/device.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  public isChecked: boolean;

  @Output() public checkboxState = new EventEmitter();

  constructor(private deviceService: DeviceService, private router: Router) {
    this.isChecked = this.router.url === '/manage-device' ? this.deviceService.checkedState : false;
  }

  ngOnInit() {
  }

  onCheckboxChange() {
    this.checkboxState.emit(this.isChecked);
  }

}
