import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ModalService} from './modal.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('modalState', [
      state('show', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('hide', style({
        opacity: 0,
        transform: 'scale(0.7)'
      })),
      transition('show => hide', animate('0.3s ease')),
      transition('hide => show', animate('0.3s ease'))
    ])
  ]
})
export class ModalComponent implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() isAlertType: boolean;
  @Input() isConfirmType: boolean;
  @Output() closeModal = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Output() decline = new EventEmitter();
  @ViewChild('modalContent') modalContent: ElementRef;
  @ViewChild('modal') modalElement: ElementRef;
  private readonly element: any;
  private showState = false;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;

    document.body.appendChild(this.element);

    this.element.addEventListener('click', (e: any) => {
      if (e.target.className === 'app-modal') {
        modal.close();
      }
    });

    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  get stateName() {
    return this.showState ? 'show' : 'hide';
  }

  open(): void {
    if (this.router.url === '/pricing' ||
      (this.router.url === '/account' && this.modalService.isPassword === true) ||
      (this.router.url === '/services' && this.modalService.isPassword === true)) {
      this.modalElement.nativeElement.classList.add('payment-modal');
    } else {
      this.modalElement.nativeElement.classList.remove('payment-modal');
    }
    this.showState = !this.showState;
    this.element.style.display = 'block';
    document.body.classList.add('app-modal-open');
  }

  close(): void {
    this.showState = !this.showState;
    this.modalContent.nativeElement.classList.add('modal-destroy');
    setTimeout(() => {
      document.body.classList.remove('app-modal-open');
      if (this.modalContent) {
        this.modalContent.nativeElement.classList.remove('modal-destroy');
      }
      this.element.style.display = 'none';
    }, 300);
  }

  closeTop() {
    this.closeModal.emit();
  }

  confirmEmmit() {
    this.confirm.emit();
  }

  declineEmmit() {
    this.decline.emit();
  }

}
