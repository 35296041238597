import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApplePay {

  public paymentRequest = {
    country: 'US',
    currency: 'aud',
    total: {
      label: 'Demo total',
      amount: 1,
    }
  };

  constructor() {}

  get getPaymentRequestObject() {
    return this.paymentRequest;
  }
}
