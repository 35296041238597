import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterHistoryService {
  private history = [];

  constructor() {}

  set historyRoute(value) {
    this.history = [...this.history, value];
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/account';
  }
}
