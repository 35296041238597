import {Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy} from '@angular/core';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {NgSelectComponent} from '@ng-select/ng-select';
import {Country} from '../models/country';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.scss']
})
export class CountriesComponent implements OnInit, OnDestroy {

  @ViewChild('select') select: NgSelectComponent;
  @Input() device;
  @Input() selectedOption: {};
  @Input() isNewDevice: boolean;
  @Output() countryChange: EventEmitter<Country> = new EventEmitter();
  public selectedCountry: any;
  public timer$ = timer(60000);
  public destroy = new Subject();
  public isDisabled: boolean;
  public isOpen: boolean;

  constructor() {}

  ngOnInit() {
    this.selectedCountry = this.selectedOption;
  }

  onChange() {
    this.countryChange.emit(this.selectedCountry);
    this.select.close();
    if (this.isNewDevice) return;
    this.isDisabled = true;
    this.timer$.pipe(takeUntil(this.destroy)).subscribe(() => this.isDisabled = false);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
