import {Component, Input, OnInit} from '@angular/core';
import {switchMap, takeUntil} from 'rxjs/operators';
import {Subject, timer} from 'rxjs';

import {DeviceService} from '../services/device-service/device.service';
import {Device} from '../models/device';

@Component({
  selector: 'app-family-filter',
  templateUrl: './family-filter.component.html',
  styleUrls: ['./family-filter.component.scss']
})
export class FamilyFilterComponent implements OnInit {

  @Input() deviceData: Device;
  @Input() isEnabled: boolean;
  public destroy = new Subject();
  public isToggled: boolean;
  public timer$ = timer(3000);
  public timerRetry$ = timer(7000);

  constructor(
    private deviceService: DeviceService
  ) { }

  ngOnInit() {
  }

  toggleFamilyFilter(event: Event): void {
    event.stopPropagation();
    if (this.isToggled) return;
    this.isToggled = true;
    const settings = {
      settings: {
        setup_family_filter: {
          enabled: !this.isEnabled
        }
      }
    };
    this.timer$.pipe(
      switchMap(() => this.deviceService.setupServiceSettings(this.deviceData.id, 3, settings)),
      takeUntil(this.destroy)
    ).subscribe(response => {
      if (response.data.setup_completed) {
        this.setupCompleted(response);
      } else {
        this.waitAndCheckAgain();
      }
    });
  }

  private setupCompleted(response: any) {
    this.isEnabled = response.data.settings.setup_family_filter.enabled;
    this.isToggled = false;
  }

  waitAndCheckAgain(): void {
    this.timerRetry$.pipe(
      switchMap(() => this.deviceService.getUserDevice(this.deviceData.id)),
      takeUntil(this.destroy)
    ).subscribe(response => {
      if (response.data.setup_completed) {
        this.setupCompleted(response);
      } else {
        this.waitAndCheckAgain();
      }
    });
  }

}
