import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login.component';
import {RouterModule} from '@angular/router';
import {SignInComponent} from './sign-in/sign-in.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  SocialLoginModule,
  AuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider
} from 'angularx-social-login';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {HttpClientModule} from '@angular/common/http';
import {UpdatePasswordComponent} from './update-password/update-password.component';
import {SharedModule} from '../shared/shared.module';


const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('862003590914342')
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('881805112645-7qr02mfb6o64q6i4itbed6jhresmobda.apps.googleusercontent.com')
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    UpdatePasswordComponent,
    ForgotPasswordComponent,
    LoginComponent,
    SignInComponent,
    SignUpComponent],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SocialLoginModule,
    CommonModule,
    RouterModule.forChild([{path: 'login', component: LoginComponent}]),
    SharedModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }]
})
export class LoginModule {
}
