import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {flatMap, takeUntil} from 'rxjs/operators';

import {AuthService, FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import {LoginService} from '../../shared/services/login-service/login.service';

interface CaptchaData {
  data: {
    success: boolean;
  };
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {

  public htmlInputElementValue: string;
  public htmlContent: any;
  public isTypePassword = true;
  public isConfirmPassword = true;
  private responseState: any;
  private createStripeCustomerSubscription$: Subscription;
  public isEmailUsed: boolean;
  public minInputLength = 8;
  public maxInputLength = 20;
  public isFormSubmitted: boolean;
  public destroy = new Subject();

  public signUpForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
    password: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)]),
    confirmPassword: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)]),
    recaptcha: new FormControl ('', Validators.required)
  });

  constructor(
    private router: Router,
    private authService: AuthService,
    private login: LoginService
  ) {}

  ngOnInit() {
    this.getCaptchaHtmlCode();
  }

  getCaptchaHtmlCode(): void {
    this.login.getCaptchaHtml().pipe(takeUntil(this.destroy)).subscribe(res => {
      const domParser = new DOMParser();
      const parsedHtml = domParser.parseFromString(res.body, 'text/html');
      const inputElement: HTMLInputElement = parsedHtml.querySelectorAll('[name="BDC_VCID_RegisterCaptcha"]')[0] as HTMLInputElement;
      this.htmlInputElementValue = inputElement.value;
      const linkElement = parsedHtml.getElementsByClassName('BDC_CaptchaImageDiv')[0];
      const link = parsedHtml.getElementsByTagName('a')[0];
      const bdsCaptchaDiv = parsedHtml.getElementsByClassName('BDC_CaptchaDiv')[0];
      const captchaBtns = parsedHtml.getElementsByClassName('BDC_CaptchaIconsDiv')[0];
      linkElement.removeChild(link);
      bdsCaptchaDiv.removeChild(captchaBtns);
      this.htmlContent = parsedHtml.body.innerHTML;
    });
  }

  ngOnDestroy(): void {
    if (this.createStripeCustomerSubscription$) {
      this.createStripeCustomerSubscription$.unsubscribe();
    }
  }

  signUpWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {
        const providerName = 'facebook';
        this.login.socialLogin(providerName, userData.authToken).subscribe(
          response => {
            localStorage.setItem('auth', response.data.access_token);
            localStorage.setItem('id', response.data.user_id);
            this.login.isAuthenticated = true;
            this.router.navigateByUrl('/devices');
          }
        );
      }
    );
  }

  signUpWithGoogle() {
    return this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData) => {
        this.login.socialLogin('google', userData.authToken).subscribe(
          response => {
            localStorage.setItem('auth', response.data.access_token);
            localStorage.setItem('id', response.data.user_id);
            this.login.isAuthenticated = true;
            this.router.navigateByUrl('/devices');
          }
        );
      }
    );
  }

  togglePassword() {
    this.isTypePassword = !this.isTypePassword;
  }

  toggleConfirmPassword() {
    this.isConfirmPassword = !this.isConfirmPassword;
  }

  submitForm(signUpForm) {
    if (this.isFormSubmitted) return;
    this.isFormSubmitted = true;
    this.isEmailUsed = false;

    const credentials = {
      name: signUpForm.value.name,
      email: signUpForm.value.email,
      password: signUpForm.value.password,
    };

    this.login.validateCaptcha('RegisterCaptcha', signUpForm.value.recaptcha, this.htmlInputElementValue)
      .pipe(
        takeUntil(this.destroy),
        flatMap((res1: CaptchaData) => {
          if (res1.data.success) return this.login.signUp(credentials);
          this.isFormSubmitted = false;
          this.getCaptchaHtmlCode();
        })
      )
      .subscribe(saved => {
        this.isFormSubmitted = false;
        this.responseState = saved;
        localStorage.setItem('auth', saved.data.access_token);
        localStorage.setItem('id', saved.data.user_id);
        this.login.isAuthenticated = true;
        this.router.navigateByUrl('/devices');
      },
        error => {
        this.isFormSubmitted = false;
        if (error.status === 422) {
          this.isEmailUsed = true;
          this.signUpForm.reset(this.signUpForm.value);
        }
      });
  }
}
