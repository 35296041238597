import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserData} from '../../models/user-data/user-data.model';
import {environment} from '../../../../environments/environment';
import {API} from '../../http/api-variables';

export interface RemoteSignUpCredentials {
  name?: string;
  email?: string;
  password?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isAuthenticated: boolean;
  private currentPageState: string;
  private userFullInfo: any;

  constructor(private http: HttpClient) {
    this.isAuthenticated = environment.guardState;
  }

  public getLocalData<T>(key: string): T {
    const localValue = localStorage.getItem(key);
    return JSON.parse(localValue) as T;
  }

  public setLocalData<T>(key: string, data: T): void {
    const newData = JSON.stringify(data);
    localStorage.setItem(key, newData);
  }

  signIn(remoteInstanceCredentials) {
    const url = API.API_LOGIN;
    const data = {
      email: remoteInstanceCredentials.email,
      password: remoteInstanceCredentials.password,
    };

    return this.http.post(url, data) as Observable<any>;
  }

  signUp(remoteSignUpCredentials) {
    const url = API.API_REGISTER;
    const data = {
      name: remoteSignUpCredentials.name,
      email: remoteSignUpCredentials.email,
      password: remoteSignUpCredentials.password,
    };

    return this.http.post(url, data) as Observable<any>;
  }

  socialLogin(providerName, token) {
    const url = `${API.API_SOCIAL_LOGIN}/${providerName}`;
    const data = {access_token: token};
    return this.http.post(url, data) as Observable<any>;
  }

  logout() {
    const url = API.API_LOGOUT;
    return this.http.get(url) as Observable<any>;
  }

  deleteAccount() {
    const url = `${API.API_USERS}/${localStorage.getItem(('id'))}`;
    return this.http.delete(url) as Observable<any>;
  }

  forgotPassword(remoteEmail) {
    const url = API.API_FORGOT_PASS;
    const data = {email: remoteEmail};
    return this.http.post(url, data) as Observable<any>;
  }

  updatePassword(resetData) {
    const url = API.API_RESET_PASS;
    const data = {
      email: window.location.href.split('email=')[1],
      password: resetData.password,
      password_confirmation: resetData.passwordConfirm,
      token: window.location.href.split('token=')[1].split('&email=')[0]
    };

    return this.http.post(url, data) as Observable<any>;
  }

  getUserData() {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}
      ?include=subscription,subscription.plan,subscription.current_period_end_date,subscription.trial_ends_at,trial_ends_at,cards`;
    return this.http.get(url) as Observable<any>;
  }

  updateUserData(userData) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}`;
    const data: RemoteSignUpCredentials = new UserData(userData);
    return this.http.put(url, data) as Observable<any>;
  }

  set setCurrentPage(current: boolean) {
    this.currentPageState = current ? 'Sign In' : 'Sign Up';
  }

  get getCurrentPage(): string {
    return this.currentPageState;
  }

  changePassword(passwordData) {
    const  url = `${API.API_USERS}/${localStorage.getItem('id')}/change-password`;
    const data = {
      old_password: passwordData.password,
      new_password: passwordData.newPassword
    };
    return this.http.put(url, data) as Observable<any>;
  }

  getPlans() {
    return this.http.get(API.API_PLANS) as Observable<any>;
  }

  createSubscription(planId) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/subscriptions/`;
    return this.http.post(url, {plan_id: planId}) as Observable<any>;
  }

  upgradeSubscription(subscriptionId: number, planId: number) {
    const url = `${API.API_USERS}/${localStorage.getItem('id')}/subscriptions/${subscriptionId}`;
    return this.http.put(url, {new_plan_id: planId}) as Observable<any>;
  }

  get getUserFullInfo() {
    if (this.getLocalData('user-info')) {
      return this.getLocalData('user-info');
    }
    return this.userFullInfo;
  }

  set setUserFullInfo(data: any) {
    this.userFullInfo = data;
    this.setLocalData('user-info', data);
  }

  public addUserCard(token) {
    const url = `${API.API_USERS}/${localStorage.getItem(('id'))}/cards/`;
    return this.http.post(url, {stripe_card_token: token}) as Observable<any>;
  }

  public removeUserCard(cardId) {
    const url = `${API.API_USERS}/${localStorage.getItem(('id'))}/cards/`;
    return this.http.delete(`${url}${cardId}`) as Observable<any>;
  }

  public updateUserCard(cardId) {
    const url = `${API.API_USERS}/${localStorage.getItem(('id'))}/cards/`;
    return this.http.put(`${url}${cardId}`, {}) as Observable<any>;
  }

  getCaptchaHtml(): Observable<HttpResponse<any>> {
    const myParams = new HttpParams().append('c', 'register');
    return this.http.get(`${environment.apiOrigin}/get-captcha-html`, {params: myParams, responseType: 'text', withCredentials: true, observe: 'response'});
  }

  validateCaptcha(captchaId: string, captchaCode: string, captchaToken: string) {
    const data = {
      BDC_UserSpecifiedCaptchaId: captchaId,
      CaptchaCode: captchaCode,
      BDC_VCID_RegisterCaptcha: captchaToken
    };
    return this.http.post(`${environment.apiOrigin}/validate-captcha`, data, {withCredentials: true});
  }

}
