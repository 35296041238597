import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ModalService} from '../../shared/modal/modal.service';
import {LoginService} from '../../shared/services/login-service/login.service';
import {Card} from '../../shared/models/cards';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit, OnDestroy {

  @Input() cards: Card[];
  @Input() isAccount: boolean;
  public deletedCardId: number;
  public cardIndex: number;
  private destroy = new Subject();
  public defaultCardId: number;
  public selectedCard: Card;
  public modalContent: string;
  public selectedCardIndex: number;

  constructor(
    public modalService: ModalService,
    private loginService: LoginService
  ) {}

  ngOnInit() {}

  public openDefaultModal(card: Card): void {
    if (card.is_default) {
      return;
    }
    this.defaultCardId = card.id;
    this.selectedCard = card;
    this.modalService.open('setDefaultCard');
  }

  public deleteCard(): void {
    this.selectedCardIndex = -1;
    this.loginService.removeUserCard(this.deletedCardId)
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.cards.splice(this.cardIndex, 1);
        this.modalService.close('deleteCard');
      }, err => {
        if (err.status === 400) {
          this.modalService.close('deleteCard');
          this.modalContent = 'You have an active subscription. One card must remain.';
          this.modalService.open('alertModal');
        }
      }
    );
  }

  public setDefault(): void {
    this.selectedCardIndex = -1;
    this.loginService.updateUserCard(this.defaultCardId).pipe(takeUntil(this.destroy)).subscribe(() => {
      this.cards = this.cards.map(el => ({...el, ...{is_default: el.id === this.defaultCardId}}));
      this.modalService.close('setDefaultCard');
    });
  }

  public openDeleteModal(card: Card, index: number): void {
    if (card.is_default && this.cards.length > 1) {
      this.modalContent = 'You can\'t delete default card';
      this.modalService.open('alertModal');
      return;
    }
    this.deletedCardId = card.id;
    this.cardIndex = index;
    this.modalService.open('deleteCard');
  }

  public closeModal(id: string): void {
    this.selectedCardIndex = -1;
    this.modalService.close(id);
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }
}
