import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortingServices'
})
export class SortingServicesPipe implements PipeTransform {

  transform(array: any, path: string[], order: number): any[] {
    if (!array || !path || !order) {
      return array;
    }

    return array.sort((a: any, b: any) => {
      path.forEach(property => {
        a = a[property];
        b = b[property];
      });
      return a > b ? order : order * (-1);
    });
  }
}
