import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {LoginService} from './shared/services/login-service/login.service';
import {RouterHistoryService} from './shared/services/router-history/router-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'BoundaryHopper';
  public isFooter: boolean;
  private destroy = new Subject();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private routerHistoryService: RouterHistoryService
  ) {
  }

  ngOnInit() {
    this.loginService.setCurrentPage = true;
    this.router.events
      .pipe(
        takeUntil(this.destroy),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.routerHistoryService.historyRoute = urlAfterRedirects;
        this.isFooter = !(this.router.url === '/login' || this.router.url === '/scan' || this.router.url === '/manually-enter'
                        || this.router.url === '/forgot-password' || this.router.url.includes('/update-password'));
        window.scrollTo(0, 0);
    });
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }
}
