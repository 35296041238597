export class AddDeviceData {
  service = {
    isDisabled: true,
    date: '30.01.2018',
    name: 'vipdns',
    icon: 'logo.png',
    countries: [
      {countryName: 'Papua New Guinea', countryFlag: 'flag.png'},
      {countryName: 'Paragua', countryFlag: 'flag1.png'},
      {countryName: 'Peru', countryFlag: 'flag1.png'},
      {countryName: 'Philippines', countryFlag: 'flag1.png'},
      {countryName: 'Poland', countryFlag: 'flag1.png'}
    ]
  };
}
