import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../../../environments/environment';
import {API} from '../../http/api-variables';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  private stripeKey = environment.STRIPE_KEY;
  private stripeUrl = 'https://api.stripe.com/v1';
  public httpHeaders: HttpHeaders;
  private httpHeadersStripe: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    Authorization: 'Bearer ' + this.stripeKey
  });
  private url = `${API.API_USERS}/${localStorage.getItem('id')}/cards/`;

  constructor(private http: HttpClient) {}

  public createToken(credentials) {
    const url = `${this.stripeUrl}/tokens`;
    const month: string = credentials.date.slice(0, 2);
    const year: string = '20' + credentials.date.slice(-2);

    const cardData = `card[number]=${credentials.cardNumber}` +
      `&card[exp_month]=${month}` +
      `&card[exp_year]=${year}` +
      `&card[cvc]=${credentials.cvv}` +
      `&card[name]=${credentials.name}`;

    return this.http.post(url, cardData, {headers: this.httpHeadersStripe}) as Observable<any>;
  }

  public addUserCard(token) {
    return this.http.post(this.url, {stripe_card_token: token}, {headers: this.httpHeaders}) as Observable<any>;
  }

  public removeUserCard(cardId) {
    return this.http.delete(`${this.url}${cardId}`, {headers: this.httpHeaders}) as Observable<any>;
  }

  public updateUserCard(cardId) {
    return this.http.put(`${this.url}${cardId}`, {}, {headers: this.httpHeaders}) as Observable<any>;
  }
}
