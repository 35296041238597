import {Component, OnInit} from '@angular/core';
import {LoginService} from '../shared/services/login-service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isSignIn: boolean;
  public buttonText: string;

  constructor(private loginService: LoginService) {
    this.isSignIn = (this.loginService.getCurrentPage === 'Sign In');
    this.buttonText = this.isSignIn ? 'Sign Up' : 'Sign In';
    this.setCurrentPage(this.isSignIn);
  }

  ngOnInit() {
  }

  changeLoginMode(): void {
    scroll(0, 0);
    this.isSignIn = !this.isSignIn;
    this.buttonText = this.isSignIn ? 'Sign Up' : 'I already have an account';
    this.setCurrentPage(this.isSignIn);
  }

  setCurrentPage(page: boolean) {
    this.loginService.setCurrentPage = page;
  }

}
