import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, timer} from 'rxjs';
import {switchMap, switchMapTo, takeUntil} from 'rxjs/operators';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import * as moment from 'moment';

import {ModalService} from 'src/app/shared/modal/modal.service';
import {LoginService} from 'src/app/shared/services/login-service/login.service';
import {DeviceService} from 'src/app/shared/services/device-service/device.service';
import {Card} from 'src/app/shared/models/cards';
import {Plan} from 'src/app/shared/models/plan';
import {CheckboxComponent} from 'src/app/shared/checkbox/checkbox.component';

declare const window: any;
const monthInYear = 12;
const payment = {
  willBeDebited: 'will be debited from your default card once your 7 day free trial expires',
  changePlans: 'You have a subscription already. Do you want to change plans?',
  willBeDebitedFrom: 'will be debited from your **** **** ****',
  doYouWantContinue: 'card. Do you want to continue?',
};

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent implements OnInit, OnDestroy {
  @ViewChild('checkbox') public checkbox: CheckboxComponent;
  @ViewChild(SwiperComponent) public componentRef: SwiperComponent;

  public userInfo;
  public paymentModalContent: string;
  public defaultBankCard: Card;
  public isMonth: boolean;
  public bankCards: Card[];
  public chosenPlan: Plan;
  public chosenPlanCost = 0;
  public proration: number;
  public prorationAdjustment: number;
  public modalContent = '';
  public plans: Plan[];
  public allPlans = [];
  public userPlan: Plan;
  public cards: Card[];
  private destroy = new Subject();
  public isPaymentAgreed: boolean;
  public isShowProration: boolean;
  public timer$ = timer(100);
  public config: SwiperConfigInterface = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    allowTouchMove: false,
    centeredSlides: false,
    navigation: false,
    pagination: false,
    breakpoints: {
      991: {
        centeredSlides: true,
        spaceBetween: 20,
        allowTouchMove: true
      }
    }
  };

  constructor(
    private router: Router,
    private modalService: ModalService,
    private loginService: LoginService,
    private deviceService: DeviceService
  ) {
  }

  ngOnInit() {
    this.loginService.getUserData().pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.loginService.setUserFullInfo = value.data;
        this.bankCards = this.loginService.getUserFullInfo.cards;
      });
    this.getPlansData();

    this.deviceService
      .month
      .pipe(takeUntil(this.destroy))
      .subscribe(isMonth => this.isMonth = isMonth);
  }

  public setCurrentPlan(chosenPlanId) {
    this.plans = this.getPlans;
    this.plans
      .map(plan => plan.isCurrent = plan.id === chosenPlanId);
  }

  getPlansData() {
    this.loginService.getPlans()
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.allPlans = value.data;
        this.plans = this.getPlans;
        this.userPlan = this.allPlans.find((plan) => {
          if (plan.isCurrent) {
            if (plan.interval === 'yearly') {
              this.toggleCheckbox();
              this.deviceService.month.next(false);
            }
            return plan;
          }
        });
        this.setCurrentPlan(this.userPlan.id);
        this.updateSlider();
      });
  }

  updateSlider() {
    this.timer$.pipe(takeUntil(this.destroy)).subscribe(() => this.componentRef.directiveRef.update());
  }

  changePeriodMode(): void {
    this.deviceService.month.next(!this.isMonth);
    this.plans = this.getPlans;
    this.updateSlider();
  }

  toggleCheckbox(): void {
    this.changePeriodMode();
    this.checkbox.isChecked = !this.checkbox.isChecked;
  }

  public getMessage(plan: Plan, cardLastFour?: string, planPrice?: number): string {
    if (this.userInfo.trial_ends_at === null) {
      return `${ plan.cost }$ ${ payment.willBeDebited }`;
    }
    const isFuture = moment(this.userInfo.trial_ends_at).isAfter(new Date());

    return isFuture
      ? `${ plan.cost } ${ payment.willBeDebited }`
      : `${ planPrice }$ ${ payment.willBeDebitedFrom } ${ cardLastFour } ${ payment.doYouWantContinue }`;
  }

  public chooseMembership(plan: Plan) {
    this.userInfo = this.loginService.getUserFullInfo;
    this.bankCards = this.userInfo.cards;
    this.defaultBankCard = this.bankCards.find(card => card.is_default);

    if (!this.bankCards.length) {
      this.deviceService.selectedPlan = plan;
      return this.router.navigateByUrl('/add-card');
    }

    this.chosenPlan = plan;
    this.paymentModalContent = this.getMessage(this.chosenPlan, this.defaultBankCard.last_four, this.chosenPlan.cost);
    this.modalService.open('paymentModal');
  }

  cancelPayment(route) {
    this.modalService.close('selectMembership');
    this.router.navigateByUrl(route);
  }

  openPaymentModal() {
    this.modalService.close('selectMembership');
    this.modalService.open('paymentModal');
  }

  createUserSubscription(): void {
    this.loginService.createSubscription(this.chosenPlan.id)
      .pipe(
        switchMapTo(this.loginService.getUserData()),
        takeUntil(this.destroy)
      ).subscribe((value) => {
        this.addAffiliateTracking(value);
        this.isPaymentAgreed = false;
        this.chosenPlan.isCurrent = true;
        this.modalService.close('paymentModal');
        this.modalContent = `Success! You are signed up to the ${this.chosenPlan.name} plan and ready to hop those boundaries!`;
        this.modalService.open('membershipsModal');
        this.loginService.setUserFullInfo = value.data;
        this.getPlansData();
        if (this.loginService.getLocalData('internalService')) {
          const internalService: any = this.loginService.getLocalData('internalService');
          this.deviceService.setupServiceSettings(internalService.routerId, internalService.serviceId, internalService.data)
            .subscribe(() => localStorage.removeItem('internalService'));
        }
      },
        error => {
        this.isPaymentAgreed = false;
        this.modalService.close('paymentModal');
        this.router.navigateByUrl('/pricing');
        error.status === 400 ? this.modalContent = 'You have subscription already' : this.modalContent = 'Unsuccessfully payment!';
        this.modalService.open('membershipsModal');
      }
    );
  }

  addAffiliateTracking(payload: any) {
    if (payload.data.subscription && payload.data.subscription.customer) {
      window.tap('create', '13938-acc7a6', { integration: 'stripe' });
      window.tap('trial', payload.data.subscription.customer.id);
    }
  }

  upgradeUserSubscription() {
    const userData: any = this.loginService.getLocalData('user-info');
    this.loginService.upgradeSubscription(userData.subscription.id, this.chosenPlan.id)
      .pipe(
        switchMap(response => {
          this.prorationAdjustment = response.data.meta.proration_adjustment;
          this.proration = Math.abs(this.prorationAdjustment * 0.01);
          if (this.prorationAdjustment !== 0) this.isShowProration = true;
          return this.loginService.getUserData();
        })
      )
      .subscribe((val) => {
        this.isPaymentAgreed = false;
        this.loginService.setUserFullInfo = val.data;
        this.getPlansData();
        this.modalService.close('paymentModal');
        this.modalContent = `Your plan was changed to ${this.chosenPlan.name.toUpperCase()}.`;
        this.modalService.open('membershipsModal');
        this.chosenPlan.isCurrent = true;
      },
        error => this.isPaymentAgreed = false
      );
  }

  submitPayment() {
    if (this.isPaymentAgreed) return;
    this.isPaymentAgreed = true;
    if (this.userPlan && (this.userPlan !== this.chosenPlan)) this.upgradeUserSubscription();
    else this.createUserSubscription();
  }

  closeModal(id) {
    this.modalService.close(id);
    if (this.bankCards === undefined || this.bankCards.length === 0) {
      this.router.navigateByUrl('/add-card');
    }
    if (this.isShowProration) {
      this.modalService.open('paymentResponseModal');
      this.isShowProration = false;
    }
    this.updateSlider();
  }

  get getPlans() {
    let userPlanInterval;
    let userPlanName;
    if (this.loginService.getUserFullInfo && this.loginService.getUserFullInfo.subscription) {
      userPlanInterval = this.loginService.getUserFullInfo.subscription.plan.interval;
      userPlanName = this.loginService.getUserFullInfo.subscription.plan.name;

      this.allPlans = this.allPlans.map(plan =>
        ({...plan, ...{isCurrent: plan.interval === userPlanInterval && plan.name === userPlanName}}));
    }

    const monthPlans = this.allPlans.filter(plan => plan.interval === 'monthly');
    const yearPlans = this.allPlans
      .filter(plan => plan.interval === 'yearly')
      .map(plan => {
        plan.monthPrice = (plan.cost / monthInYear).toFixed(2);
        return plan;
      });

    return this.isMonth ? monthPlans : yearPlans;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
