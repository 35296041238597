import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {LoginService} from '../../shared/services/login-service/login.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {ModalService} from '../../shared/modal/modal.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  private forgotPasswordSubscription$: Subscription;
  public responseState: boolean;
  public error: string;
  public linkName: string;
  public linkText: string;

  public forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
    ]),
  });

  constructor(
    private modalService: ModalService,
    private loginService: LoginService,
    private location: Location,
    private router: Router) {
    this.linkName = this.loginService.getCurrentPage;
    this.linkText = (this.loginService.getCurrentPage === 'Sign In') ? 'Sign Up' : 'Sign In';
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.forgotPasswordSubscription$) {
      this.forgotPasswordSubscription$.unsubscribe();
    }
  }

  submitForm(forgotPasswordForm) {
    this.forgotPasswordSubscription$ = this.loginService.forgotPassword(forgotPasswordForm.value.email).pipe(first()).subscribe(
      () => {
        this.responseState = true;
        this.modalService.open('confirmEmail');
      }, error => {
        if (error.status === 422) {
          this.responseState = false;
          this.error = error.error.errors.email[0];
          this.modalService.open('confirmEmail');
        }
      }
    );
  }

  changeState() {
    this.location.back();
    this.loginService.setCurrentPage = (this.loginService.getCurrentPage === 'Sign In');
  }

  closeModal() {
    this.modalService.close('confirmEmail');
    this.router.navigateByUrl('/login');
  }
}
