import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../shared/services/login-service/login.service';
import {Router} from '@angular/router';
import {ModalService} from '../../shared/modal/modal.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {

  private forgotPasswordSubscription$: Subscription;
  private responseState: any;
  public isTypePassword = true;
  public isConfirmPassword = true;
  public minInputLength = 8;
  public maxInputLength = 20;

  public updatePasswordForm: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)
    ]),
    passwordConfirm: new FormControl('', [
      Validators.required, Validators.minLength(this.minInputLength), Validators.maxLength(this.maxInputLength)
    ])
  });

  constructor(private login: LoginService, private router: Router, private modalService: ModalService) {}

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.forgotPasswordSubscription$) {
      this.forgotPasswordSubscription$.unsubscribe();
    }
  }

  togglePassword() {
    this.isTypePassword = !this.isTypePassword;
  }

  toggleConfirmPassword() {
    this.isConfirmPassword = !this.isConfirmPassword;
  }

  submitForm(updatePasswordForm) {
    this.forgotPasswordSubscription$ = this.login.updatePassword(updatePasswordForm.value).pipe(first()).subscribe(
      response => {
        this.responseState = response;
        this.modalService.open('confirmPassword');
      },
      error => {
            this.modalService.open('confirmPasswordError');
          }
    );
  }


  closeModal() {
    this.modalService.close('confirmPassword');
    this.router.navigateByUrl('/login');
  }
}
