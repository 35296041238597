import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {environment} from '../../../../environments/environment';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GooglePay {
  private environment = environment;
  public destroy = new Subject();
  public googlePayClient = null;
  public baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS']
    }
  };
  public googlePayBaseConfiguration = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [this.baseCardPaymentMethod]
  };
  public tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'stripe',
      'stripe:version': '2018-10-31',
      'stripe:publishableKey': `${environment.STRIPE_KEY}`
    }
  };
  public cardPaymentMethod = {
    type: 'CARD',
    tokenizationSpecification: this.tokenizationSpecification,
    parameters: {
      allowedCardNetworks: ['MASTERCARD', 'VISA'],
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS']
    }
  };
  public transactionInfo = {
    totalPriceStatus: 'FINAL',
    totalPrice: '1',
    currencyCode: 'AUD'
  };
  public paymentDataRequest = Object.assign({}, this.googlePayBaseConfiguration, {
    allowedPaymentMethods: [this.cardPaymentMethod],
    transactionInfo: this.transactionInfo,
    merchantInfo: {
      merchantName: 'BoundaryHopper',
      merchantId: environment.merchantId
    }
  });

  constructor() {
    if (this.environment.production) {
      this.initGooglePayClient('PRODUCTION');
      return;
    }
    this.initGooglePayClient('TEST');
  }

  private initGooglePayClient(env: string) {
    this.googlePayClient = new google.payments.api.PaymentsClient({
      environment: env
    });
  }

  get GooglePayClient() {
    return this.googlePayClient;
  }

  get GooglePayBaseConfig() {
    return this.googlePayBaseConfiguration;
  }

  get PaymentDataRequest() {
    return this.paymentDataRequest;
  }
}
