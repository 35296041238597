import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {NgSelectModule} from '@ng-select/ng-select';

import {SortingServicesPipe} from './pipes/sorting/sorting-services.pipe';
import {SearchServicePipe} from './pipes/search/search-service.pipe';
import {CardNumberPipe} from './pipes/card-number/card-number.pipe';
import {CountriesComponent} from './countries/countries.component';
import {ModalComponent} from './modal/modal.component';
import {FooterComponent} from './footer/footer.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {FamilyFilterComponent} from './family-filter/family-filter.component';
import {GooglePay} from './services/external-payments/google-pay';
import {ApplePay} from './services/external-payments/apple-pay';

@NgModule({
  declarations: [
    CountriesComponent,
    CardNumberPipe,
    SearchServicePipe,
    SortingServicesPipe,
    ModalComponent,
    CheckboxComponent,
    FooterComponent,
    FamilyFilterComponent,
  ],
  imports: [
    ScrollToModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule,
    RouterModule,
    FormsModule,
    CommonModule
  ],
  exports: [
    CardNumberPipe,
    SearchServicePipe,
    CountriesComponent,
    SortingServicesPipe,
    ModalComponent,
    CheckboxComponent,
    FooterComponent,
    FamilyFilterComponent
  ],
  providers: [
    GooglePay,
    ApplePay
  ]
})
export class SharedModule {
}
