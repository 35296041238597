import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HelpComponent} from './account/help/help.component';
import {ScanComponent} from './device/scan/scan.component';
import {MembershipsComponent} from './account/memberships/memberships.component';
import {AddDeviceComponent} from './device/add-device/add-device.component';
import {ManageDeviceComponent} from './device/manage-device/manage-device.component';
import {AddServiceComponent} from './device/add-service/add-service.component';
import {AddCardComponent} from './account/add-card/add-card.component';
import {ServicesComponent} from './device/services/services.component';
import {ManuallyEnterComponent} from './device/scan/manually-enter/manually-enter.component';
import {AccountComponent} from './account/account.component';
import {DeviceComponent} from './device/device.component';
import {ForgotPasswordComponent} from './login/forgot-password/forgot-password.component';
import {UpdatePasswordComponent} from './login/update-password/update-password.component';
import {AuthGuard} from './shared/services/guards/auth-guard.service';
import {DeviceGuard} from './shared/services/guards/device-guard.guard';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', loadChildren: './login/login.module#LoginModule'},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'update-password', component: UpdatePasswordComponent},
  {path: 'devices', component: DeviceComponent, canActivate: [AuthGuard]},
  {path: 'account', component: AccountComponent , canActivate: [AuthGuard]},
  {path: 'help', component: HelpComponent, canActivate: [AuthGuard]},
  {path: 'scan', component: ScanComponent, canActivate: [AuthGuard]},
  {path: 'pricing', component: MembershipsComponent, canActivate: [AuthGuard]},
  {path: 'add-device', component: AddDeviceComponent, canActivate: [AuthGuard, DeviceGuard]},
  {path: 'manage-device/:id', component: ManageDeviceComponent, canActivate: [AuthGuard]},
  {path: 'add-service', component: AddServiceComponent, canActivate: [AuthGuard]},
  {path: 'manually-enter', component: ManuallyEnterComponent, canActivate: [AuthGuard]},
  {path: 'add-card', component: AddCardComponent, canActivate: [AuthGuard]},
  {path: 'services/:id', component: ServicesComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
